



































































import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import { SearchParam } from './index';
import pageSizeChange from '@/mixin/pagesize';
import { RecordApi } from '@/api/systemmanage';
import dayjs from 'dayjs';
import { Message } from 'element-ui';
export default defineComponent({
  mixins: [pageSizeChange],
  setup() {
    const SearchParams = reactive({ logType: '1' } as SearchParam);
    const pageTable = ref();
    const state = reactive({
      SearchParams,
      OperatesList: [],
      DateTime: [] as any,
    });
    const handleSearch = () => {
      pageTable.value.onLoad();
    };
    const getOperatorList = () => {
      RecordApi.searchCreatedByOperator('').then(res => {
        state.OperatesList = res.data;
      });
    };
    const clear = () => {
      state.SearchParams = {
        logType: '1',
        startTime: state.SearchParams.startTime,
        endTime: state.SearchParams.endTime,
      };
      state.DateTime = [
        state.SearchParams.startTime,
        state.SearchParams.endTime,
      ];
    };
    onMounted(() => {
      const now = new Date();
      const endTime = dayjs(now).format('YYYY-MM-DD HH:mm:ss');
      // console.log(startTime );
      const startTime = dayjs(
        dayjs(endTime)
          .subtract(7, 'day')
          .toString(),
      ).format('YYYY-MM-DD HH:mm:ss');
      // console.log(endTime);
      state.SearchParams.startTime = startTime;
      state.SearchParams.endTime = endTime;
      state.DateTime = [startTime, endTime];
      handleSearch();
      getOperatorList();
    });
    return {
      ...toRefs(state),
      pageTable,
      handleSearch,
      clear,
      getOperatorList,
      findPage: async (params: any) => {
        if (!state.SearchParams.endTime || !state.SearchParams.startTime) {
          Message.warning('请输入查询日期');
          return;
        }
        if (
          dayjs(state.SearchParams.endTime).diff(
            state.SearchParams.startTime,
            'month',
          ) > 6
        ) {
          Message.warning('查询日期最多6个月');
          return;
        }
        const res = await RecordApi.searchSyslog(params);
        res.data.records.forEach(
          (element: {
            createTime: string | number | Date | dayjs.Dayjs | null | undefined;
          }) => {
            element.createTime = dayjs(element.createTime).format(
              'YYYY-MM-DD HH:mm:ss',
            );
          },
        );
        return res;
      },
      DateChange: (date: string[]) => {
        state.SearchParams.startTime = date?.[0];
        state.SearchParams.endTime = date?.[1];
      },
    };
  },
});
