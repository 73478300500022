













































































































































































import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import { SearchParam, statusText } from './withdal';
import { FinalmageApi } from '@/api/finalmanage';
import { Message } from 'element-ui';
import Popover from '@/components/Popover.vue';
import { getFormatTime } from '@/utils/tools';
export default defineComponent({
  components: {
    Popover,
  },
  setup() {
    const pageTable = ref();
    const RefuseDialog = ref();
    const SearchParams = reactive({ checkStatus: '' } as SearchParam);
    const state = reactive({
      SearchParams,
      DateTime: [],
      tableData: [],
      statusText,
      StatusList: Object.keys(statusText).map(key => ({
        label: (statusText as any)[key],
        value: key,
      })),
      refouseInfo: {
        remarks: '',
        id: '',
        checkStatus: '',
        realName: '',
        withdrawalPrice: '',
      },
      selArr: [],
      refuseDialogText: '审核拒绝', //二次弹框 通过、拒绝通过文案
    });
    const handleSearch = () => {
      pageTable.value.onLoad();
    };
    const clear = () => {
      state.DateTime = [];
      state.SearchParams = { checkStatus: '' };
    };
    const updateStatus = () => {
      const msg =
        state.refouseInfo.checkStatus === '1' ? '审核通过' : '拒绝成功';
      if (state.refouseInfo.checkStatus === '2' && !state.refouseInfo.remarks) {
        Message.warning('请输入拒绝提现原因');
        return;
      }
      const { id, remarks, checkStatus } = state.refouseInfo;
      FinalmageApi.withvwupdateStatus({
        id,
        remarks,
        checkStatus,
      }).then(() => {
        Message.success(msg);
        RefuseDialog.value.dialogFormVisibles = false;
        handleSearch();
      });
    };
    const showRefouse = (
      row: {
        id: string;
        remarks: string;
        realName: string;
        withdrawalPrice: any;
      },
      index: string,
    ) => {
      RefuseDialog.value.dialogFormVisibles = true;
      const { id, remarks, realName, withdrawalPrice } = row;
      state.refouseInfo = {
        id,
        remarks,
        checkStatus: index,
        realName: realName,
        withdrawalPrice: (Number(withdrawalPrice) / 100).toFixed(2),
      };
      state.refuseDialogText = index === '1' ? '审核通过' : '审核拒绝';
    };
    onMounted(() => {
      handleSearch();
    });
    return {
      ...toRefs(state),
      pageTable,
      RefuseDialog,
      getFormatTime,
      handleSearch,
      updateStatus,
      showRefouse,
      clear,
      findPage: FinalmageApi.withvwfindPage,
      withvwCheckrmk: FinalmageApi.withvwCheckrmk,
      DateChange: (date: string[]) => {
        state.SearchParams.startTime = date?.[0];
        state.SearchParams.endTime = date?.[1];
      },
      handleSelectionChange: (arr: any) => {
        state.selArr = arr.map((item: { id: string }) => {
          return item.id;
        });
      },
      wdwExport: () => {
        FinalmageApi.withvwExport({ ...state.SearchParams, ids: state.selArr });
        setTimeout(() => {
          pageTable.value.clearSelection();
        }, 200);
      },
    };
  },
});
