














import { defineComponent, reactive, toRefs } from '@vue/composition-api';
export default defineComponent({
  props: {
    value: {
      type: String,
      default() {
        return '';
      },
    },
    referenceText: {
      type: String,
      default: '',
    },
    api: {
      type: Function,
    },
    remarkKey: {
      type: String,
      default: '',
    },
  },
  setup(props: {
    api: (arg0: any) => Promise<any>;
    value: any;
    remarkKey: string | number;
  }) {
    // console.log(props);

    const state = reactive({
      remarks: '',
    });
    const getRemarks = () => {
      props.api(props.value).then(res => {
        state.remarks = res.data?.[props.remarkKey];
      });
    };
    return {
      ...toRefs(state),
      getRemarks,
    };
  },
});
