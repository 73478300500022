








































































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import Upload from '@/components/Upload.vue';
import WangEditor from '@/components/WangEditor.vue';
import { OpermageApi } from '@/api/opermanage';
import { StoreInfoType } from './index';
import router from '@/router';
import { Message } from 'element-ui';
import CardLeft from './cardleft.vue';
export default defineComponent({
  components: {
    Upload,
    WangEditor,
    CardLeft,
  },
  setup() {
    const salesCardForm = ref();
    const AddData = {
      coverImg: '',
      storeId: '',
      cardId: '',
      activityRemark: '',
      merchantDetailImg: '',
      homePageImgList: [],
    };
    const state = reactive({
      AddData,
      ShopList: [],
      Formrule: {
        coverImg: [
          { required: true, message: '请上传卡封面', trigger: 'blur' },
        ],
        storeId: [
          { required: true, message: '请选择默认门店', trigger: 'blur' },
        ],
        activityRemark: [
          { required: true, message: '请添加活动说明', trigger: 'blur' },
        ],
        homePageImgList: [
          {
            required: true,
            message: '请上传卡封面',
            trigger: 'blur',
          },
        ],
      },
      imgHost: 'https://ka-img-dev.billbear.cn',
      cardInfo: {},
      storeInfo: {} as StoreInfoType | undefined,
    });
    // 获取门店列表
    const getShopList = () => {
      OpermageApi.findStoreManagerByCardId(state.AddData.cardId).then(res => {
        state.ShopList = res.data; //门店列表
        if (state.AddData.storeId)
          state.storeInfo = res.data.find(
            (item: any) => item.id === state.AddData.storeId,
          );
      });
    };
    const keepdata = () => {
      salesCardForm.value.validate((val: boolean) => {
        if (!val) return;
        OpermageApi.configuration(state.AddData).then(() => {
          Message.success('配置成功');
          router.go(-1);
        });
      });
    };
    const Isdisabled = computed(() => {
      if (state.AddData.cardId) {
        return false;
      }
    });
    const onStoreOptionChange = (storeId: string) => {
      state.storeInfo = state.ShopList.find((item: any) => item.id === storeId);
    };
    onMounted(() => {
      const routers: any = router;
      state.AddData.cardId = routers?.history.current.params.id;

      if (state.AddData.cardId) {
        OpermageApi.configFindById(state.AddData.cardId).then(res => {
          state.AddData = res.data;
          getShopList();
        });
        OpermageApi.findById(state.AddData.cardId).then(res => {
          state.cardInfo = {
            ...res.data,
            initDiscount: res.data.initDiscountRequest.initDiscount,
            minDiscount: res.data.minDiscountRequest.minDiscount,
          };
        });
      }
    });
    return {
      ...toRefs(state),
      getShopList,
      onStoreOptionChange,
      Isdisabled,
      cancel: () => {
        router.go(-1);
      },
      keepdata,
      salesCardForm,
    };
  },
});
