<template>
  <router-link :to="to" style="margin-left: 10px">
    <el-button :type="type" :icon="icon"><slot /></el-button>
  </router-link>
</template>

<script>
export default {
  props: {
    to: [String, Object],
    type: {
      type: String,
      default: 'text',
    },
    icon: String,
  },
};
</script>

<style></style>
