






































































































import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import { AddParam } from './index';
import { MenuApi } from '@/api/systemmanage';
import { Message } from 'element-ui';
export default defineComponent({
  props: {
    isAdd: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const AddForm = ref();
    const AddDialog = ref();
    const AddData = reactive({ type: 'MENU', visible: true } as AddParam);
    const state = reactive({
      AddData,
      options: [],
      Formrule: {
        name: [{ required: true, message: '请输入菜单标题', trigger: 'blur' }],
        rule: [
          { required: true, message: '权限标识不能为空', trigger: 'blur' },
        ],
      },
    });
    const isMenu = computed(() => {
      return state.AddData?.type === 'MENU' ? true : false;
    });
    const TypeChange = (val: string) => {
      if (val === 'MENU') {
        state.AddData = reactive({
          type: state.AddData.type,
          visible: true,
        } as AddParam);
      } else {
        state.AddData = reactive({ type: state.AddData.type } as AddParam);
      }
    };
    const handleSearch = () => {
      MenuApi.findTree({}).then(res => {
        state.options = res.data;
      });
    };
    onMounted(() => {
      handleSearch();
    });
    return {
      ...toRefs(state),
      AddForm,
      isMenu,
      AddDialog,
      TypeChange,
      finishkeep: (isAdd: boolean) => {
        AddForm.value.validate((val: boolean) => {
          if (!val) return;
          let parentId;
          if (state.AddData?.parentId) {
            parentId = Array.isArray(state.AddData?.parentId)
              ? state.AddData.parentId[state.AddData.parentId.length - 1]
              : state.AddData?.parentId;
          } else {
            parentId = 0;
          }
          const params = {
            ...state.AddData,
            parentId: parentId,
          };
          const apipost = isAdd ? MenuApi.addMenu : MenuApi.editMenu;
          const msg = isAdd ? '保存成功' : '修改成功';
          apipost(params).then(() => {
            Message.success(msg);
            AddDialog.value.dialogFormVisibles = false;
            emit('confirm');
          });
        });
      },
      dialoginit: (row: any) => {
        if (row) {
          const {
            id,
            name,
            type,
            visible,
            sort,
            parentId,
            component,
            rule,
          } = row;
          state.AddData = {
            id,
            name,
            type,
            visible,
            sort,
            parentId,
            component,
            rule,
          };
        } else {
          state.AddData = { type: 'MENU', visible: true };
        }
        handleSearch();
        AddDialog.value.open();
        nextTick(() => {
          AddForm.value.clearValidate();
        });
      },
    };
  },
});
