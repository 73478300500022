





















































































































































































































































import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import { SearchParam, StatusText, CouponStatus } from './index';
import { OpermageApi } from '@/api/opermanage';
import { MessageBox, Message } from 'element-ui';
import Relationshop from './relationshop.vue';
import router from '@/router';
import { amountToFixedByDecimalDigits } from '@/utils/tools';
import pageSizeChange from '@/mixin/pagesize';

export default defineComponent({
  mixins: [pageSizeChange],
  components: {
    Relationshop,
  },
  setup() {
    const SearchParams = reactive({
      status: '',
      merchantName: '',
      merchantId: '',
    } as SearchParam);
    const pageTable = ref();
    const dialogVisible = ref();
    const state = reactive({
      SearchParams,
      StatusList: Object.keys(StatusText).map(key => ({
        label: (StatusText as any)[key],
        value: key,
      })),
      StatusText,
      CouponStatus,
      DateTime: [],
      isshowshop: false,
      cardId: '', //共享卡当前id
      couponData: {},
      tooltipStatus: false,
    });
    const Nameisdisd = computed(() => {
      if (state.SearchParams.merchantId) return true;
      else return false;
    });
    // watch(() => state.SearchParams.merchantId,
    //   (val, prevVal) => {
    //     /* ... */
    //     console.log(val,2, prevVal);
    //     if(val) {
    //       state.Nameisdisd = true
    //     }else {
    //       state.Nameisdisd = false
    //     }
    //   },
    // );
    const handleSearch = () => {
      pageTable.value.onLoad();
      pageTable.value.$refs.tableRef.doLayout();
      // nextTick(()=> {
      //   pageTable.value.doLayout();
      // })
    };
    onMounted(() => {
      const routers: any = router;
      state.SearchParams.merchantId = routers.history.current.params.merchantId;
      state.SearchParams.merchantName =
        routers.history.current.params.merchantName;
      if (state.SearchParams.merchantId) state.SearchParams.status = '0';
      handleSearch();
      console.log(CouponStatus.NORMAL);
    });
    const isShow = (status: string | number) => {
      return (state.StatusText as any)[status] === '上架';
    };
    return {
      ...toRefs(state),
      pageTable,
      dialogVisible,
      Nameisdisd,
      amountToFixedByDecimalDigits,
      isShow,
      handleSearch,
      clear: () => {
        const routers: any = router;
        if (routers.history.current.params?.merchantId) {
          state.SearchParams = {
            merchantId: state.SearchParams.merchantId,
            merchantName: state.SearchParams.merchantName,
            status: '',
          };
        } else {
          state.SearchParams = {
            status: '',
          };
        }
        state.DateTime = [];
      },
      DateChange: (date: (string | undefined)[]) => {
        state.SearchParams.startTime = date?.[0];
        state.SearchParams.endTime = date?.[1];
      },
      findPage: async (params: any) => {
        const res = OpermageApi.findPage(params);
        (await res).data.records = (await res).data.records.map(
          (item: { initDiscount: any; minDiscount: any }) => ({
            ...item,
            initDiscount: JSON.parse(`${item.initDiscount}`),
            minDiscount: JSON.parse(`${item.minDiscount}`),
          }),
        );
        return res;
      },
      upperdownShelves: (row: { id: any }, isd: number) => {
        // 上下架
        const msg =
          isd === 1
            ? '请确认售卡页面是否已配置，再进行上架操作'
            : '下架后此卡不再售卖，并隐藏售卡详情，以及该卡售卖入口。但已卖出的卡，且在有效期内的，依旧可以使用，直至过有效期。是否确认下架？';
        MessageBox.confirm(`${msg}`, {
          distinguishCancelAndClose: true,
          showClose: false,
          center: true,
        })
          .then(() => {
            const params = {
              cardId: row.id,
            };
            const ApiUrl =
              isd === 1 ? OpermageApi.Cardpush : OpermageApi.Cardpull;
            const tip = isd === 1 ? '上架' : '下架';
            ApiUrl(params)
              .then(() => {
                Message.success(`${tip}成功`);
                handleSearch();
              })
              .catch(() => {
                Message.error(`${tip}失败，请重试`);
              });
          })
          .catch(() => {
            Message.info('取消了');
          });
      },
      relationshop: (row: { merchantId: any; storeIds: []; id: string }) => {
        // 关联门店
        state.isshowshop = true;
        const params = {
          merchantId: row.merchantId,
          value: row.storeIds,
        };
        state.cardId = row.id;
        nextTick(() => {
          dialogVisible.value.dialoginit(params);
        });
      },
      CloseDialog: () => {
        // 门店弹框关闭
        state.isshowshop = false;
        handleSearch();
      },
      Addcard: () => {
        router.push('/opermanage/shardcardadd');
      },
      editCard: (row: { id: any }) => {
        router.push(`/opermanage/shardcardadd/${row.id}`);
      },
      salesCard: (row: { id: any; merchantId: any }) => {
        router.push(`/opermanage/shardcardsale/${row.id}/${row.merchantId}`);
      },
      hoverTip: (row: any) => {
        OpermageApi.getCouponById(row.id).then(res => {
          state.couponData = res.data;
          row.tooltipStatus = true;
        });
      },
      leaveTip: (row: any) => {
        row.tooltipStatus = false;
      },
      // getcouponData: (row: { id: string; tooltipStatus: boolean }) => {
      //   OpermageApi.getCouponById(row.id).then(res => {
      //     state.couponData = res.data;
      //     row.tooltipStatus = true;
      //   });
      // },
    };
  },
});
