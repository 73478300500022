




























































import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import pageSizeChange from '@/mixin/pagesize';
import { RoleApi } from '@/api/systemmanage';
import AddRole from './add.vue';
import { Message, MessageBox } from 'element-ui';
import MenuPermisons from './menupermissions.vue';
export default defineComponent({
  mixins: [pageSizeChange],
  components: {
    AddRole,
    MenuPermisons,
  },
  setup() {
    const pageTable = ref();
    const Adddialog = ref();
    const Menudiaolog = ref();
    const state = reactive({
      SearchParams: {
        roleName: '',
      },
      isAdd: false,
      menuIds: [],
    });
    const handleSearch = () => {
      pageTable.value.onLoad();
    };
    const clear = () => {
      state.SearchParams.roleName = '';
    };
    const edit = (row: any) => {
      if (row) {
        state.isAdd = false;
      } else {
        state.isAdd = true;
      }
      Adddialog.value.dialoginit(row);
    };
    const del = (row: { id: string }) => {
      MessageBox.confirm('是否确认删除该权限？', {
        distinguishCancelAndClose: true,
        showClose: false,
        center: true,
      })
        .then(() => {
          RoleApi.deleteById(row.id).then(() => {
            Message.success('删除成功');
            handleSearch();
          });
        })
        .catch(() => {
          Message.info('取消操作了');
        });
    };
    onMounted(() => {
      handleSearch();
    });
    return {
      ...toRefs(state),
      pageTable,
      Adddialog,
      Menudiaolog,
      handleSearch,
      clear,
      edit,
      findPage: RoleApi.findPage,
      del,
      menuEdit: (row: { menuIds: never[] }) => {
        state.menuIds = row.menuIds;
        Menudiaolog.value.dialoginit(row);
      },
    };
  },
});
