/**
 *列表搜索参数
 */
export interface SearchParam {
  mobile?: string; //手机号
  status?: string; // 状态
  registrationStartTime?: string; //开始日期
  registrationEndTime?: string; //结束日期
}
/**
 *状态
 */
export const StatusText = {
  '': '全部',
  0: '正常',
  2: '已冻结',
};
