










































































import distribuData from './distribuData.vue';
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import { SearchParam } from './index';
import { CouponmageApi } from '@/api/opermanage';
import request from '@/utils/request';
import router from '@/router';
import { Message } from 'element-ui';
import pageSizeChange from '@/mixin/pagesize';
export default defineComponent({
  mixins: [pageSizeChange],
  components: {
    distribuData,
  },
  setup() {
    const dialogVisible = ref(null);
    const SearchParams = reactive({} as SearchParam);
    const pageTable = ref();
    const state = reactive({
      SearchParams,
    });
    const handleSearch = () => {
      pageTable.value.onLoad();
    };
    onMounted(() => {
      // const routers: any = router;
      // state.SearchParams.merchantId = routers.history.current.params.merchantId;
      handleSearch();
    });
    return {
      dialogVisible,
      ...toRefs(state),
      exportList() {
        request.post(
          '/scAdmin/admin/distributionManager/exportMerchant',
          state.SearchParams,
          { responseType: 'blob' },
        );
      },
      pageTable,
      getDistribuDate: (row: any) => {
        const el: any = dialogVisible.value;
        el.dialoginit(row);
      },
      findPage: (params: any) => {
        return request.post(
          '/scAdmin/admin/distributionManager/findMerchantTotalPage',
          params,
        );
      },
      handleSearch,
      clear: () => {
        state.SearchParams = {};
      },
      addcoupon: () => {
        router.push('/opermanage/couponadd');
      },
      disable: (row: { id: any }) => {
        CouponmageApi.disable(row.id).then(() => {
          Message.success('禁用成功');
          handleSearch();
        });
      },
      enable: (row: { id: any }) => {
        CouponmageApi.enable(row.id).then(() => {
          Message.success('启用成功');
          handleSearch();
        });
      },
      showdel: (row: { id: any }) => {
        router.push(`/distribution/setting/${row.id}`);
      },
    };
  },
});
