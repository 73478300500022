










































































































































import {
  defineComponent,
  nextTick,
  onMounted,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import { AddParam } from './index';
import { MertmentApi } from '@/api/mertmanget';
import { ShopmageApi } from '@/api/opermanage';
import { Message } from 'element-ui';
export default defineComponent({
  props: {
    isAdd: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const AddData = reactive({ areaCode: '' } as AddParam);
    const AddForm = ref();
    const AddDialog = ref();
    const showMap = ref(false);
    const province = (rule: any, value: any, callback: any) => {
      if (!value) {
        callback(new Error(`请选择省`));
      } else if (!AddForm.value.model.cityCode) {
        callback(new Error(`请选择市`));
      } else if (!AddForm.value.model.areaCode) {
        callback(new Error(`请选择区`));
      }
      callback();
    };
    const state = reactive({
      AddData,
      MerchantList: [],
      AreaTree: [] || null,
      provincelist: [],
      citylist: [],
      arealist: [],
      proviceflag: '',
      cityflag: '',
      Formrule: {
        merchantId: [
          { required: true, message: '请选择商户', trigger: 'change' },
        ],
        storeName: [
          { required: true, message: '请输入门店名称', trigger: 'change' },
          { min: 4, max: 100, message: '4-100个字符内', trigger: 'change' },
        ],
        provinceCode: [
          { required: true, validator: province, trigger: 'change' },
        ],
        mobile: [
          { required: false, message: '请输入手机号码', trigger: 'blur' },
          { min: 11, max: 11, message: '请输入11位手机号码', trigger: 'blur' },
          {
            pattern: /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
            message: '请输入正确的手机号码',
          },
        ],
        address: [
          { required: true, message: '请输入详细地址', trigger: 'blur' },
        ],
        longitude: [{ required: true, message: '请输入', trigger: 'blur' }],
        latitude: [{ required: true, message: '请输入', trigger: 'blur' }],
      },
    });
    const CHINA = {
      name: '中国',
      flag: 'ZH',
      type: 'COUNTRY',
    };
    const getAreaTree = async (params: any, idx: string) => {
      await ShopmageApi.arealist(params).then(res => {
        switch (idx) {
          case '1':
            state.provincelist = res.data;
            break;
          case '2':
            state.citylist = res.data;
            break;
          case '3':
            state.arealist = res.data;
            break;
        }
      });
    };
    const getCity = async () => {
      if (!state.AddData.provinceCode) {
        Message.warning('请先选择省');
        return;
      }
      const Provice = {
        flag: state.proviceflag,
        type: 'PROVINCE',
      };
      await getAreaTree(Provice, '2');
    };
    const proviceChange = (val: string) => {
      const parent = state.provincelist.find(
        i => (i as any).code === state.AddData.provinceCode,
      ) as any;
      state.citylist = [];
      state.AddData.cityCode = '';
      state.arealist = [];
      state.AddData.areaCode = '';
      if (val) {
        state.AddData.province = parent.name;
        state.proviceflag = parent.flag;
        getCity();
      } else {
        state.AddData.province = '';
        state.proviceflag = '';
      }
    };
    const getArea = () => {
      if (!state.AddData.cityCode) {
        Message.warning('请先选择市');
        return;
      }
      const City = {
        flag: state.cityflag,
        type: 'CITY',
        code: state.AddData.cityCode,
      };
      getAreaTree(City, '3');
    };
    const cityChange = (val: string) => {
      const parent = state.citylist.find(
        i => (i as any).code === state.AddData.cityCode,
      ) as any;
      state.arealist = [];
      state.AddData.areaCode = '';
      if (val) {
        state.AddData.city = parent.name;
        state.cityflag = parent.flag;
        getArea();
      } else {
        state.AddData.city = '';
        state.cityflag = '';
      }
    };

    const areaChange = (val: string) => {
      const parent = state.arealist.find(
        i => (i as any).code === state.AddData.areaCode,
      ) as any;
      if (val) {
        state.AddData.area = parent.name;
      } else {
        state.AddData.area = '';
      }
    };
    const merchantChange = (val: string) => {
      if (val) {
        state.AddData.merchantName = (state.MerchantList.find(
          i => (i as any).id === state.AddData.merchantId,
        ) as any).name;
      } else {
        state.AddData.merchantName = '';
      }
    };
    onMounted(() => {
      // 商户下拉列表
      MertmentApi.findPage({ pageSize: 9999, currentPage: 1, status: 0 }).then(
        res => {
          state.MerchantList = res.data.records;
        },
      );
      getAreaTree(CHINA, '1');
    });
    return {
      isShowMap() {
        showMap.value = !showMap.value;
      },
      showMap,
      getPosition: (location: any) => {
        state.AddData.longitude = location.lng;
        state.AddData.latitude = location.lat;
      },
      ...toRefs(state),
      AddForm,
      AddDialog,
      proviceChange,
      getAreaTree,
      cityChange,
      getCity,
      getArea,
      areaChange,
      merchantChange,
      finishkeep: (isAdd: any) => {
        AddForm.value.validate((val: boolean) => {
          if (!val) return;
          const params = {
            ...state.AddData,
          };
          const postApi = isAdd ? ShopmageApi.add : ShopmageApi.edit;
          const msg = isAdd ? '添加成功' : '修改成功';
          postApi(params).then(() => {
            Message.success(msg);
            AddDialog.value.dialogFormVisibles = false;
            emit('confirm');
          });
        });
      },
      dialoginit: (row: any) => {
        state.AddData = row
          ? row
          : { areaCode: '', longitude: '', latitude: '' };
        if (row) {
          state.proviceflag = (state.provincelist.find(
            i => (i as any).code === state.AddData.provinceCode,
          ) as any).flag;
          getCity().then(() => {
            state.cityflag = (state.citylist.find(
              i => (i as any).code === state.AddData.cityCode,
            ) as any).flag;
            getArea();
          });
        }
        AddDialog.value.open();
        nextTick(() => {
          AddForm.value.clearValidate();
        });
      },
    };
  },
});
