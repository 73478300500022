import request from '../utils/request';

export const FinalmageApi = {
  orderfindPage(params: any) {
    return request.post('/scAdmin/admin/orderInfo/search', params); //订单流水
  },
  refundRemarkById(orderNo: string) {
    return request.get(
      `/scAdmin/admin/orderInfo/search/refundRemark/${orderNo}`,
    ); //退款备注
  },
  refund(params: any) {
    return request.post('/scAdmin/admin/orderInfo/refund', params); //订单流水 退款
  },
  orderInfoExport(params: any) {
    return request.post('/scAdmin/admin/orderInfo/export', params, {
      responseType: 'blob',
    }); //订单流水 导出
  },
  withvwExport(params: any) {
    return request.post('/scAdmin/admin/extractCheck/export', params, {
      responseType: 'blob',
    }); //提现审核 导出
  },
  withvwfindPage(params: any) {
    return request.post('/scAdmin/admin/extractCheck/search', params); //提现审核查询
  },
  withvwupdateStatus(params: any) {
    return request.post('/scAdmin/admin/extractCheck/updateStatus', params); //提现审核 通过，拒绝
  },
  withvwCheckrmk(id: string) {
    return request.get(
      `/scAdmin/admin/extractCheck/searchExtractCheckRemark/${id}`,
    ); //提现审核 拒绝原因查询
  },
  withSetSave(params: any) {
    return request.post('/scAdmin/admin/extractSetting/save', params); //提现设置
  },
  withSetSearch(params: any) {
    return request.post('/scAdmin/admin/extractSetting/search', params); //提现设置 查询
  },
  paysetSave(params: any) {
    return request.post('/scAdmin/admin/payConfig/savePayConfig', params); //支付设置
  },
  paysetSearch(params: any) {
    return request.get('/scAdmin/admin/payConfig/searchPayConfig', params); //支付设置 查询
  },
};
