






















































import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import { UserAgretApi } from '@/api/opermanage';
import WangEditor from '@/components/WangEditor.vue';
import { Message } from 'element-ui';
export default defineComponent({
  components: {
    WangEditor,
  },
  setup() {
    const userForm = ref();
    const state = reactive({
      userInfo: {
        show: true,
        privacyAgreementList: [
          {
            id: '',
            agreementType: '0',
            agreementName: '',
            agreementContent: '',
          },
          {
            id: '',
            agreementType: '1',
            agreementName: '',
            agreementContent: '',
          },
        ],
      },
      Formrule: {
        agreementName: [
          { required: true, message: '请输入协议名称', trigger: 'blur' },
        ],
        agreementContent: [
          { required: true, message: '请选择协议正文', trigger: 'blur' },
        ],
      },
    });
    const searchFormData = () => {
      UserAgretApi.searchPrAment('').then(res => {
        state.userInfo.show = res.data.show;
        if (res.data?.privacyAgreementList?.length > 0) {
          state.userInfo.privacyAgreementList = res.data.privacyAgreementList;
        }
      });
    };
    onMounted(() => {
      searchFormData();
    });
    return {
      ...toRefs(state),
      userForm,
      searchFormData,
      keepdata: () => {
        console.log(userForm, 9);
        let m = 0;
        userForm.value.forEach((item: any) => {
          item.validate((val: boolean) => {
            if (!val) {
              m = 1;
            }
          });
        });
        if (m === 0) {
          UserAgretApi.savePrAment(state.userInfo).then(res => {
            console.log(res);

            Message.success('保存成功');
            searchFormData();
          });
        }
      },
      cancel: () => {
        userForm.value.clearValidate();
      },
    };
  },
});
