






































import {
  defineComponent,
  nextTick,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import { AddParam } from './index';
import { RoleApi } from '@/api/systemmanage';
import { Message } from 'element-ui';
import { copy } from '@/constants/mertmanget';
export default defineComponent({
  props: {
    isAdd: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const AddData = reactive({} as AddParam);
    const AddDialog = ref();
    const AddForm = ref();
    const state = reactive({
      AddData,
      Formrule: {
        roleName: [
          { required: true, message: '请输入角色名称', trigger: 'blur' },
        ],
      },
    });
    return {
      ...toRefs(state),
      AddDialog,
      AddForm,
      finishkeep: (isAdd: any) => {
        AddForm.value.validate((val: boolean) => {
          if (!val) return;
          const params = {
            ...state.AddData,
          };
          const postApi = isAdd ? RoleApi.addRole : RoleApi.editRole;
          const msg = isAdd ? '添加成功' : '修改成功';
          postApi(params).then(() => {
            Message.success(msg);
            AddDialog.value.dialogFormVisibles = false;
            emit('confirm');
          });
        });
      },
      dialoginit: (row: any) => {
        state.AddData = row ? copy(row) : {};
        AddDialog.value.open();
        nextTick(() => {
          AddForm.value.clearValidate();
        });
      },
    };
  },
});
