

































































































import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import { SearchParam, CouponText, StatusText } from './index';
import { CouponmageApi } from '@/api/opermanage';
import router from '@/router';
import { Message } from 'element-ui';
import pageSizeChange from '@/mixin/pagesize';
export default defineComponent({
  mixins: [pageSizeChange],
  setup() {
    const SearchParams = reactive({
      couponType: 'ALL',
      status: 'ALL',
    } as SearchParam);
    const pageTable = ref();
    const state = reactive({
      SearchParams,
      CouponList: Object.keys(CouponText).map(key => ({
        label: (CouponText as any)[key],
        value: key,
      })),
      StatusList: Object.keys(StatusText).map(key => ({
        label: (StatusText as any)[key],
        value: key,
      })),
      StatusText,
      CouponText,
    });
    const handleSearch = () => {
      pageTable.value.onLoad();
    };
    onMounted(() => {
      const routers: any = router;
      state.SearchParams.merchantId = routers.history.current.params.merchantId;
      handleSearch();
    });
    return {
      ...toRefs(state),
      pageTable,
      findPage: CouponmageApi.findPage,
      handleSearch,
      clear: () => {
        state.SearchParams = {
          couponType: 'ALL',
          status: 'ALL',
          merchantId: state.SearchParams.merchantId,
        };
      },
      addcoupon: () => {
        router.push('/opermanage/couponadd');
      },
      disable: (row: { id: any }) => {
        CouponmageApi.disable(row.id).then(() => {
          Message.success('禁用成功');
          handleSearch();
        });
      },
      enable: (row: { id: any }) => {
        CouponmageApi.enable(row.id).then(() => {
          Message.success('启用成功');
          handleSearch();
        });
      },
      showdel: (row: { id: any }, type: any) => {
        router.push(`/opermanage/couponadd/${row.id}/${type}`);
      },
    };
  },
});
