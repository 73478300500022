/**
 *列表搜索参数
 */
export interface SearchParam {
  checkStatus?: string; //审核状态
  merchantName?: string; // 商户名称
  mobile?: string; //手机号
  startTime?: string; //开始日期
  endTime?: string; //结束日期
  storeName?: string; //门店名称
}

/**
 * # 提现审核 状态
 */
export const statusText = {
  0: '待审核',
  1: '已审核',
  2: '已拒绝',
};
