









































import {
  defineComponent,
  nextTick,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import SelectShop from '@/components/SelectShop.vue';
import { OpermageApi } from '@/api/opermanage';
import { Message } from 'element-ui';
export default defineComponent({
  components: {
    SelectShop,
  },
  props: ['cardId'],
  setup(props: any, { emit }) {
    const state = reactive({
      selectArr: [],
      merchantId: '',
    });
    const ShopDialog = ref();
    const selshop = ref();
    return {
      ...toRefs(state),
      ShopDialog,
      selshop,
      CloseDialog: () => {
        emit('close');
      },
      dialoginit: (params: any) => {
        ShopDialog.value.open();
        nextTick(() => {
          state.merchantId = params.merchantId;
          state.selectArr = params.value;
          selshop.value.init(params);
        });
      },
      UpDialog: () => {
        if (state.selectArr.length === 0) {
          Message.warning('请选择关联门店');
          return;
        }
        const params = {
          storeIds: state.selectArr,
          cardId: props.cardId, //共享卡id
        };
        OpermageApi.relationStore(params).then(() => {
          Message.success('关联门店成功');
          ShopDialog.value.dialogFormVisibles = false;
          emit('close');
        });
      },
    };
  },
});
