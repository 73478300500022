



































import { defineComponent, reactive, ref, toRefs } from '@vue/composition-api';
import { Message } from 'element-ui';
import { FinalmageApi } from '@/api/finalmanage';
export default defineComponent({
  props: {
    refundInfo: {
      type: Object,
      default: {},
    },
  },
  setup(props, { emit }) {
    const RefundDialog = ref();
    const state = reactive({
      refundRemark: '',
      refundInfos: props.refundInfo,
    });
    return {
      ...toRefs(state),
      RefundDialog,
      finishkeep: () => {
        if (!state.refundRemark) {
          Message.warning('请输入退款原因');
          return;
        }
        FinalmageApi.refund({
          orderNo: state.refundInfos.orderNo,
          refundRemark: state.refundRemark,
        }).then(() => {
          Message.success('退款成功');
          RefundDialog.value.dialogFormVisibles = false;
          emit('confirm');
        });
      },
      dialoginit: (row: any) => {
        state.refundInfos = row;
        state.refundRemark = '';
        RefundDialog.value.open();
      },
    };
  },
});
