





















































import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import { HotcityApi } from '@/api/opermanage';
import { Message } from 'element-ui';
export default defineComponent({
  setup() {
    const pageTable = ref();
    const state = reactive({
      isEdit: false,
      tableData: [] as any,
    });
    const handleSearch = () => {
      pageTable.value.onLoad();
    };
    onMounted(() => {
      handleSearch();
    });
    return {
      ...toRefs(state),
      findPage: async () => {
        const res = await HotcityApi.findPage();
        state.tableData = res.data;
        return res;
      },
      pageTable,
      handleSearch,
      keepData: () => {
        HotcityApi.save({ hotCityList: state.tableData }).then(() => {
          Message.success('保存成功');
          state.isEdit = false;
        });
      },
    };
  },
});
