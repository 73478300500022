















































































import {
  defineComponent,
  nextTick,
  onMounted,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import { AddParam } from './index';
import { AccountApi, RoleApi } from '@/api/systemmanage';
import { Message } from 'element-ui';
import { copy } from '@/constants/mertmanget';
export default defineComponent({
  props: {
    isAdd: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const AddData = reactive({} as AddParam);
    const AddDialog = ref();
    const AddForm = ref();
    const state = reactive({
      AddData,
      Formrule: {
        realName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        username: [
          { required: true, message: '账号不能为空', trigger: 'blur' },
        ],
        mobile: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' },
        ],
        roleIds: [
          {
            type: 'array',
            required: true,
            message: '请选择角色',
            trigger: 'change',
          },
        ],
        confimpsd: [
          { required: true, message: '确认密码不能为空', trigger: 'blur' },
        ],
      },
      roleList: [],
    });
    const getRoleList = () => {
      RoleApi.findPage({ pageSize: 9999, currentPage: 1 }).then(res => {
        state.roleList = res.data.records;
      });
    };
    onMounted(() => {
      getRoleList();
    });
    return {
      ...toRefs(state),
      AddDialog,
      AddForm,
      finishkeep: (isAdd: any) => {
        AddForm.value.validate((val: boolean) => {
          if (!val) return;
          const params = {
            ...state.AddData,
          };
          if (params.confimpsd != params.password) {
            Message.warning('设置密码与确认密码不一致');
            return;
          }
          delete params.confimpsd;
          const postApi = isAdd
            ? AccountApi.addAccount
            : AccountApi.editAccount;
          const msg = isAdd ? '添加成功' : '修改成功';
          postApi(params).then(() => {
            Message.success(msg);
            AddDialog.value.dialogFormVisibles = false;
            emit('confirm');
          });
        });
      },

      dialoginit: (row: any) => {
        if (row) {
          getRoleList();
        }
        state.AddData = row ? copy(row) : {};
        AddDialog.value.open();
        nextTick(() => {
          AddForm.value.clearValidate();
        });
      },
    };
  },
});
