import { defineComponent, reactive, toRefs } from '@vue/composition-api';
const pageSizeChange = defineComponent({
  setup() {
    const state = reactive({
      currentPage: 1,
      pageSize: 50,
    });
    return {
      ...toRefs(state),
      handlePageChange: (val: number) => {
        state.currentPage = val;
      },
      handleSizeChange: (val: number) => {
        state.pageSize = val;
      },
    };
  },
});
export default pageSizeChange;
