
























import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import { MenuApi, RoleApi } from '@/api/systemmanage';
import { copy } from '@/constants/mertmanget';
import { Message } from 'element-ui';
export default defineComponent({
  props: {
    menuIds: {
      type: Array,
    },
  },
  setup(props, { emit }) {
    const menudialog = ref();
    const menutree = ref();
    const state = reactive({
      Menulist: [],
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      formData: {},
    });
    const getMenuTree = () => {
      MenuApi.findTree({}).then(res => {
        state.Menulist = res.data;
      });
    };
    onMounted(() => {
      getMenuTree();
    });
    return {
      ...toRefs(state),
      menudialog,
      menutree,
      getMenuTree,
      finishkeep: () => {
        const checkedKeys = menutree.value.getCheckedKeys();
        const params = {
          ...state.formData,
          menuIds: checkedKeys,
        };
        RoleApi.editRole(params).then(() => {
          Message.success('菜单权限修改成功');
          menudialog.value.dialogFormVisibles = false;
          emit('confirm');
        });
      },
      dialoginit: (row: any) => {
        state.formData = row ? copy(row) : '';
        getMenuTree();
        menudialog.value.open();
      },
    };
  },
});
