


























































































































































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import { MertmentApi } from '@/api/mertmanget';
import * as echarts from 'echarts';
import dayjs from 'dayjs';
import CountTo from '@/components/vue-count-to.vue';
import { Message } from 'element-ui';
import { amountToFixedByDecimalDigits } from '@/utils/tools';
export default defineComponent({
  components: { CountTo },
  setup() {
    const myChart = ref<HTMLElement>(); //柱状图
    const myCharts = ref<any>();
    const myCouponChart = ref<HTMLElement>(); // 优惠券 饼图
    const myCouponCharts = ref<any>();
    const secondCardChart = ref<HTMLElement>(); // 副卡 饼图
    const secondCardCharts = ref<any>();
    const state = reactive({
      merchantId: [],
      options: [],
      DateTime: [] as any,
      searchParams: {
        startTime: '',
        endTime: '',
        chartType: '0',
        merchantId: '',
        storeId: '',
      },
      startVal: 0,
      endVal: 43233,
      totalInfo: {
        salesCardTotalPrice: 0,
        storeTotalNum: 0,
        userTotalNum: 0,
      },
      viceCardInfo: {
        receiveRatio: '00.00%',
        received: 0,
        unreceived: 0,
        viceCardAmount: 0,
      },
      couponInfo: {
        dataList: [],
      },
      shareCouponInfo: {} as any,
      myselfCouponInfo: {} as any,
      isMonth: false,
    });
    // 优惠券信息数据
    const getCoupons = async () => {
      return await MertmentApi.selectCoupon();
    };
    const gettabText = computed(() => {
      return state.searchParams.chartType === '0' ? '售卡数量' : '新增用户数量';
    });
    console.log(gettabText);

    // 柱状图信息数据
    const getMycharts = async () => {
      const params = {
        ...state.searchParams,
        merchantId:
          state.searchParams.chartType === '0' ? state.merchantId?.[0] : '',
        storeId:
          state.searchParams.chartType === '0' ? state.merchantId?.[1] : '',
      };
      return await MertmentApi.selectChartData(params);
    };
    //优惠券 饼状图
    const initCouponEchats = async () => {
      const res = await getCoupons();
      state.couponInfo = res.data;
      res.data.dataList.map((item: any) => {
        if (item.couponType === '0') {
          state.myselfCouponInfo = item;
        } else if (item.couponType === '1') {
          state.shareCouponInfo = item;
        }
      });
      setTimeout(() => {
        myCouponCharts.value = echarts.init(myCouponChart.value!);
        myCouponCharts.value.setOption({
          tooltip: {
            trigger: 'item',
          },
          legend: {
            top: '5%',
            left: 'center',
            show: false,
          },
          series: [
            {
              name: '券',
              type: 'pie',
              radius: ['65%', '90%'],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 5,
                borderColor: '#fff',
                borderWidth: 2,
              },
              label: {
                show: false,
                position: 'center',
              },
              emphasis: {
                label: {
                  show: false,
                },
              },
              labelLine: {
                show: false,
              },
              data: [
                {
                  value:
                    (state.shareCouponInfo.freePrice +
                      state.myselfCouponInfo.freePrice) /
                    100,
                  name: '未使用金额',
                  itemStyle: { color: '#fbd438' },
                },
                {
                  value:
                    (state.shareCouponInfo.usedPrice +
                      state.myselfCouponInfo.usedPrice) /
                    100,
                  name: '使用金额',
                  itemStyle: { color: '#3ba1ff' },
                },
              ],
            },
          ],
        });

        secondCardCharts.value = echarts.init(secondCardChart.value!);
        secondCardCharts.value.setOption({
          tooltip: {
            trigger: 'item',
          },
          legend: {
            top: '5%',
            left: 'center',
            show: false,
          },
          series: [
            {
              name: '副卡',
              type: 'pie',
              radius: ['65%', '90%'],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 5,
                borderColor: '#fff',
                borderWidth: 2,
              },
              label: {
                show: false,
                position: 'center',
              },
              emphasis: {
                label: {
                  show: false,
                },
              },
              labelLine: {
                show: false,
              },
              data: [
                {
                  value: state.viceCardInfo.received,
                  name: '已领取数量',
                  itemStyle: { color: '#fbd438' },
                },
                {
                  value: state.viceCardInfo.unreceived,
                  name: '未领取数量',
                  itemStyle: { color: '#3ba1ff' },
                },
              ],
            },
          ],
        });
      }, 100);
    };
    // 柱状图
    const initMyCharts = async () => {
      const res = await getMycharts();
      const Xdata = res.data.map((item: { time: string }) => {
        return item.time;
      });
      const Ydata = res.data.map((item: { number: number }) => {
        return item.number;
      });
      setTimeout(() => {
        // 绘制图表
        myCharts.value = echarts.init(myChart.value!);
        myCharts.value.setOption({
          title: { text: '' },
          tooltip: {},
          xAxis: {
            type: 'category',
            data: Xdata,
          },
          yAxis: {
            type: 'value',
          },
          series: [
            {
              name: '售卡数量',
              type: 'bar',
              barWidth: 20,
              //设置柱状图渐变颜色
              itemStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(
                    0,
                    1,
                    0,
                    0,
                    [
                      {
                        offset: 0,
                        color: '#1268f3', // 0% 处的颜色
                      },
                      {
                        offset: 0.6,
                        color: '#08a4fa', // 60% 处的颜色
                      },
                      {
                        offset: 1,
                        color: '#01ccfe', // 100% 处的颜色
                      },
                    ],
                    false,
                  ),
                },
              },
              data: Ydata,
            },
          ],
        });
      }, 10);
    };

    // 商户、门店数据获取
    const merchantOptions = () => {
      MertmentApi.selectTitleOption().then(res => {
        state.options = res.data.map((item: any) => ({
          id: item.merchantId,
          name: item.merchantName,
          children: item.storeDataList.map((items: any) => ({
            id: items.storeId,
            name: items.storeName,
          })),
        }));
      });
    };
    // 获取汇总数据
    const getTotalData = () => {
      MertmentApi.selectDataOverview().then(res => {
        state.totalInfo = res.data;
        console.log(state.totalInfo);
      });
    };
    // 获取副卡信息
    const getViceCardData = () => {
      MertmentApi.selectViceCard().then(res => {
        state.viceCardInfo = res.data;
      });
    };

    onMounted(() => {
      const now = new Date();
      const endTime = dayjs(now).format('YYYY-MM-DD');
      const startTime = dayjs(
        dayjs(endTime)
          .subtract(7, 'day')
          .toString(),
      ).format('YYYY-MM-DD');
      state.isMonth = false;
      state.DateTime = [startTime, endTime];
      state.searchParams.startTime = startTime;
      state.searchParams.endTime = endTime;
      initCouponEchats();
      initMyCharts();
      merchantOptions();
      getTotalData();
      getViceCardData();
    });
    return {
      ...toRefs(state),
      myChart,
      myCouponChart,
      secondCardChart,
      gettabText,
      amountToFixedByDecimalDigits,
      merchantOptions,
      initCouponEchats,
      initMyCharts,
      getTotalData,
      getViceCardData,
      getCoupons,
      DateChange: (date: string[]) => {
        if (!date[1] || !date[0]) {
          Message.warning('请输入查询日期');
          return;
        }
        if (dayjs(date[1]).diff(date[0], 'month') >= 1) {
          Message.warning('查询日期最多1个月');
          state.DateTime = [];
          state.searchParams.startTime = '';
          state.searchParams.endTime = '';
          return;
        }
        state.searchParams.startTime = date?.[0];
        state.searchParams.endTime = date?.[1];
        initMyCharts();
      },
      handleClick: (tab: { name: any }) => {
        console.log(tab.name);
        initMyCharts();
      },
      monthCheck: (flag: boolean) => {
        let startTime;
        const now = new Date();
        const endTime = dayjs(now).format('YYYY-MM-DD');
        if (flag === false) {
          startTime = dayjs(
            dayjs(endTime)
              .subtract(7, 'day')
              .toString(),
          ).format('YYYY-MM-DD');
        } else {
          startTime = dayjs(
            dayjs(endTime)
              .subtract(1, 'month')
              .toString(),
          ).format('YYYY-MM-DD');
        }
        state.isMonth = flag;
        state.DateTime = [startTime, endTime];
        state.searchParams.startTime = startTime;
        state.searchParams.endTime = endTime;
        initMyCharts();
      },
    };
  },
});
