























































































import { IsCouponsApi } from '@/api/opermanage';
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import { SearchParam } from './index';
import pageSizeChange from '@/mixin/pagesize';
import Add from './add.vue';
import ReisueCoupons from './reisuecoupons.vue';
export default defineComponent({
  mixins: [pageSizeChange],
  components: {
    Add,
    ReisueCoupons,
  },
  setup() {
    const pageTable = ref();
    const Adddialog = ref(); //添加补发券 //补发券数据
    const SearchParams = reactive({ merchantId: '' } as SearchParam);
    const state = reactive({
      SearchParams,
      MerchantList: [],
      isAdd: false,
      isResult: false,
      rowId: '' as string,
    });
    const handleSearch = () => {
      pageTable.value.onLoad();
    };
    const clear = () => {
      state.SearchParams = reactive({ merchantId: '' } as SearchParam);
    };
    const getMerchantList = () => {
      // 商户下拉列表
      IsCouponsApi.merchantOption('').then(res => {
        state.MerchantList = res.data;
      });
    };
    onMounted(() => {
      getMerchantList();
      handleSearch();
    });
    return {
      ...toRefs(state),
      handleSearch,
      pageTable,
      Adddialog,
      getMerchantList,
      findPage: IsCouponsApi.findPage,
      clear,
      showResult: (row: { id: string }) => {
        state.rowId = row.id;
        state.isResult = true;
      },
    };
  },
});
