
















































































































































































import {
  computed,
  defineComponent,
  PropType,
  reactive,
  toRefs,
} from '@vue/composition-api';
import { StoreInfoType } from './index';
export default defineComponent({
  props: {
    activityRemark: {
      type: String,
      default: '',
    },
    merchantDetailImg: {
      type: String,
      default: '',
    },
    imgHost: {
      type: String,
      default: '',
    },
    cardInfo: {
      type: Object,
    },
    storeInfo: {
      type: Object as PropType<StoreInfoType>,
      default: () => ({}),
    },
    coverImg: {
      type: String,
    },
  },
  setup(props: any) {
    const cardBackImg = computed(() => {
      return props.coverImg ? props.imgHost + '/' + props.coverImg : '';
    });
    const logoImg = computed(() => {
      return props.cardInfo.logoImg
        ? props.imgHost + '/' + props.cardInfo.logoImg
        : '';
    });
    const state = reactive({
      Remark: '',
      ImgList: [],
    });
    return {
      ...toRefs(state),
      cardBackImg,
      logoImg,
    };
  },
});
