import request from '@/utils/request';
// 商户列表
export const BusinessApi = {
  totalVerifierBoard(params: any) {
    return request.post(
      '/scAdmin/admin/verifier/record/totalVerifierBoard',
      params,
    ); //核销面板数据
  },
  vferfindPage(params: any) {
    return request.post('/scAdmin/admin/verifier/record/findPage', params); //核销分页
  },
  showvferDel(id: string) {
    return request.get(`/scAdmin/admin/verifier/record/${id}`); //核销详情
  },
  vferexport(params: any) {
    return request.post('/scAdmin/admin/verifier/record/export', params, {
      responseType: 'blob',
    }); //核销导出
  },
  salesCardfindPage(params: any) {
    return request.post('/scAdmin/admin/salesCard/record/findPage', params); //售卡记录分页
  },
  salesStoreList(params: any) {
    return request.post(
      '/scAdmin/admin/salesCard/record/searchStoreOption',
      params,
    ); //售卡记录 售卡门店下拉
  },
  salesCardexport(params: any) {
    return request.post('/scAdmin/admin/salesCard/record/export', params, {
      responseType: 'blob',
    }); //售卡记录导出
  },
};
// 共享卡
export const OpermageApi = {
  findPage(params: any) {
    return request.post('/scAdmin/admin/shareCard/findPage', params); //共享卡查询
  },
  Cardpush(cardId: any) {
    return request.post('/scAdmin/admin/shareCard/push', cardId); //共享卡上架
  },
  Cardpull(cardId: any) {
    return request.post('/scAdmin/admin/shareCard/pull', cardId); //共享卡下架
  },
  relationStore(params: any) {
    return request.post('/scAdmin/admin/shareCard/relationStore', params); //共享卡关联门店
  },
  add(params: any) {
    return request.post('/scAdmin/admin/shareCard/add', params);
  },
  edit(params: any) {
    return request.post('/scAdmin/admin/shareCard/edit', params);
  },
  findById(id: string) {
    return request.get(`/scAdmin/admin/shareCard/findById/${id}`); //共享卡查询详情
  },
  getCouponById(id: string) {
    return request.get(`/scAdmin/admin/shareCard/getCouponById/${id}`); //共享卡获取优惠券详情
  },
  configuration(params: any) {
    return request.post(
      '/scAdmin/admin/salesCard/manager/configuration',
      params,
    ); //售卡配置
  },
  configFindById(id: string) {
    return request.get(`/scAdmin/admin/salesCard/manager/findByCardId/${id}`); //售卡配置查看详情
  },
  findStoreManagerByCardId(cardId: string) {
    return request.get(
      `/scAdmin/admin/storeManager/findStoreManagerByCardId/${cardId}`,
    ); //售卡配置 查询绑定门店列表
  },
};
// 优惠券
export const CouponmageApi = {
  findPage(params: any) {
    return request.post('/scAdmin/admin/coupon/manager/findPage', params);
  },
  add(params: any) {
    return request.post('/scAdmin/admin/coupon/manager/add', params);
  },
  edit(params: any) {
    return request.post('/scAdmin/admin/coupon/manager/edit', params);
  },
  enable(id: any) {
    return request.get(`/scAdmin/admin/coupon/manager/enable/${id}`); //启用
  },
  disable(id: any) {
    return request.get(`/scAdmin/admin/coupon/manager/disable/${id}`); //禁用
  },
  findById(id: string) {
    return request.get(`/scAdmin/admin/coupon/manager/findById/${id}`); //详情
  },
};
// 门店
export const ShopmageApi = {
  findPage(params: any) {
    return request.post('/scAdmin/admin/storeManager/findPage', params);
  },
  add(params: any) {
    return request.post('/scAdmin/admin/storeManager/add', params);
  },
  edit(params: any) {
    return request.put('/scAdmin/admin/storeManager/edit', params);
  },
  delete(id: string) {
    return request.delete(`/scAdmin/admin/storeManager/${id}`);
  },
  arealist(params: any) {
    return request.post('/scAdmin/admin/area/list', params);
  },
  generateUrl(params: any) {
    return request.post(
      `/scAdmin/admin/storeManager/generateUrl?time=${+new Date()}`,
      params,
      {
        responseType: 'arraybuffer',
      },
    ); //生成台卡
  },
  findShareCardByStore(storeId: any) {
    return request.get(
      `/scAdmin/admin/shareCard/findShareCardByStore/${storeId}`,
    );
  },
  findUserByMerchant(params: any) {
    return request.post(
      `/scAdmin/admin/verifierMember/findUserByMerchant`,
      params,
    );
  },
  generatePromotionUrl(params: any) {
    return request.post(
      `/scAdmin/admin/storeManager/generatePromotionUrl?time=${+new Date()}`,
      params,
      {
        responseType: 'arraybuffer',
      },
    );
  },
  editStoreStatus(params: any) {
    return request.post(`/scAdmin/admin/storeManager/editStoreStatus`, params);
  },
};
// 核销员
export const WriteoffApi = {
  findPage(params: any) {
    return request.post('/scAdmin/admin/verifierMember/findPage', params);
  },
  add(params: any) {
    return request.post('/scAdmin/admin/verifierMember/add', params);
  },
  edit(params: any) {
    return request.put('/scAdmin/admin/verifierMember/edit', params);
  },
  detail(id: string) {
    return request.get(`/scAdmin/admin/verifierMember/${id}`);
  },
  delete(id: string) {
    return request.delete(`/scAdmin/admin/verifierMember/${id}`);
  },
};
// 热门城市
export const HotcityApi = {
  findPage() {
    return request.get('/scAdmin/admin/hotCity');
  },
  save(params: any) {
    return request.post('/scAdmin/admin/hotCity/save', params);
  },
};
// 用户协议和隐私协议
export const UserAgretApi = {
  searchPrAment(params: any) {
    return request.post(
      '/scAdmin/admin/privacyAgreement/searchPrivacyAgreement',
      params,
    ); //查询隐私协议
  },
  savePrAment(params: any) {
    return request.post(
      '/scAdmin/admin/privacyAgreement/savePrivacyAgreement',
      params,
    ); //保存
  },
};
// 发券管理
export const IsCouponsApi = {
  findPage(params: any) {
    return request.post('/scAdmin/admin/reissueCouponManager/search', params); //列表查询
  },
  reissCoupon(params: any) {
    return request.post(
      '/scAdmin/admin/reissueCouponManager/reissueCoupon',
      params,
    ); //补发优惠券
  },
  merchantOption(params: any) {
    return request.post(
      '/scAdmin/admin/reissueCouponManager/merchantOption',
      params,
    ); //商户下拉查询
  },
  selectUserShareCard(userId: string) {
    return request.get(
      `/scAdmin/admin/reissueCouponManager/selectUserShareCard/${userId}`,
    ); //当前用户下共享卡
  },
  selectUserInfoByMobile(mobile: string) {
    return request.get(
      `/scAdmin/admin/reissueCouponManager/selectUserInfoByMobile/${mobile}`,
    ); //根据手机号查询用户信息
  },
  selectCouponInfo(params: { userId: string; shareCardId: string }) {
    return request.get(
      `/scAdmin/admin/reissueCouponManager/selectCouponInfo/${params.userId}/${params.shareCardId}`,
    ); //查询共享卡下优惠券
  },
  searchReissueCouponDetail(params: { id: any; mobile: any }) {
    return request.post(
      `/scAdmin/admin/reissueCouponManager/searchReissueCouponDetail/${params.id}`,
      params,
    ); //查询优惠券数据
  },
  selectUserCardNo(params: { userId: string; shareCardId: string }) {
    return request.get(
      `/scAdmin/admin/reissueCouponManager/selectUserCardNo/${params.userId}/${params.shareCardId}`,
    ); //查询共享卡下主副卡数据
  },
};
export const TabApi = {
  findPage(params: any) {
    return request.post('/scAdmin/admin/cardLabel/findPage', params);
  },
  selectUserCardNo(params: { userId: string; shareCardId: string }) {
    return request.get(
      `/scAdmin/admin/reissueCouponManager/selectUserCardNo/${params.userId}/${params.shareCardId}`,
    ); //查询共享卡下主副卡数据
  },
};
// 页签管理
export const TabManage = {
  findPage(params: any) {
    return request.post('/scAdmin/admin/cardLabel/findPage', params); //列表查询
  },
  save(params: any) {
    return request.post('/scAdmin/admin/cardLabel/saveLabel', params); //保存
  },
};
