<template>
  <countTo
    style="font-size:24px"
    :startVal="startVal"
    :endVal="endVal"
    :decimals="decimals"
    :duration="3000"
  ></countTo>
</template>
<script>
import countTo from 'vue-count-to';
export default {
  components: { countTo },
  props: {
    startVal: {
      type: [String, Number],
    },
    endVal: {
      type: [String, Number],
    },
    decimals: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {};
  },
};
</script>
