/**
 * 列表搜索参数
 */
export interface SearchParam {
  name?: string;
  status?: string; //账号
}
export const MENU_STATUS = {
  NORMAL: true,
  DISABLED: false,
};

/**
 * 菜单类型
 */
export const MenuTypeText = {
  MENU: '菜单',
  BUTTON: '按钮',
};

/**
 * 过滤树形数据
 * @param treeList
 * @param customizer
 */
export function filterTree(
  treeList: any[],
  customizer: (value: any) => boolean,
): any[] {
  if (!treeList?.length) return [];
  return treeList.filter(item => {
    if (item?.children?.length) {
      item.children = filterTree(item?.children, customizer);
      if (item?.children?.length) return true;
    }
    return customizer(item);
  });
}

/**
 * 新增菜单参数
 */
export interface AddParam {
  id?: string;
  name?: string; //菜单标题
  type?: string; // 类型 按钮or菜单
  visible?: boolean; //是否可见
  sort?: number; // 排序
  parentId?: any; //上级菜单
  component?: string; // 路径地址
  rule?: string; //权限标识
}
