/**
 *列表搜索参数
 */
export interface SearchParam {
  merchantId?: string;
  merchantName?: string; // 商户名称
  cardName?: string; //卡名称
  status?: string; // 状态
  startTime?: string; //卡添加日期
  endTime?: string; //结束日期
}
/**
 *状态
 */
export const StatusText = {
  '': '全部',
  0: '上架',
  2: '下架',
};

/**
 *添加共享卡
 */
export interface AddParam {
  id?: string;
  merchantId?: string;
  cardLabelId?: string;
  timeType?: string;
  cardName?: string;
  initDiscountRequest?: {
    initDiscount?: number;
    lowerDiscount?: number;
    effectQuota?: number;
  };
  minDiscountRequest?: {
    minDiscount?: number;
    lowerDiscount?: number;
    effectQuota?: number;
  };
  mainTime?: number;
  viceTime?: number;
  viceNum?: number;
  sellMoney?: number;
  cardCouponRequestList?: any;
}

/**
 *主卡有效期
 */
export const MasterCardText = {
  3: '3个月',
  6: '6个月',
  12: '12个月',
};

/**
 *副卡有效期
 */
export const SecondCardText = {
  1: '1个月',
  2: '2个月',
  3: '3个月',
  6: '6个月',
  12: '12个月',
};

// 当前obj数据 同步
export function editvalue(a: { [x: string]: any }, b: { [x: string]: any }) {
  Object.keys(a).forEach(key => {
    //key=>属性名  obj[key]=>属性值
    Object.keys(b).forEach(key2 => {
      if (key == key2) {
        return (a[key] = b[key2]);
      }
    });
  });
}

// 优惠券状态
export enum CouponStatus {
  NORMAL = '0',
  DELETE = '1',
  DISABLE = '2',
}
// 门店信息
export interface StoreInfoType {
  id: string;
  storeName: string;
  province: string;
  city: string;
  [x: string]: any;
}

// 主副卡有效期选择
export enum ValidityType {
  DAY = '天',
  MONTH = '月',
}
