






































































import {
  computed,
  defineComponent,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import { Message } from 'element-ui';
import { CardManageApi } from '@/api/usermanage';
export default defineComponent({
  setup(props, { emit }) {
    const AddDialog = ref();
    const state = reactive({
      AddData: {
        coupons: [],
      } as any,
      couponId: [] as any,
      selCoupon: {} as any,
      noSelCp: false, //是否已选择优惠券
    });
    // 判断核销按钮  当前卡折扣已降低到最低折扣
    const disedVerifRecord = computed(() => {
      return state.AddData.nowDiscount === state.AddData.minDiscount;
    });
    // 仅核销优惠券 禁用 优惠券显示与否
    const couponLength = computed(() => {
      return state.AddData.coupons.length === 0 ? true : false;
    });

    // 优惠券切换
    const couponChange = () => {
      if (state.couponId.length > 1) {
        state.couponId.splice(0, 1);
      }
      const parent = state.AddData.coupons?.[state.couponId?.[0]];
      if (parent) {
        const { userCouponId, reissueSign } = parent;
        state.selCoupon = userCouponId ? { userCouponId, reissueSign } : {};
      } else {
        state.selCoupon = {};
      }
    };
    const clear = () => {
      state.AddData = {
        coupons: [],
      };
      state.couponId = [];
      state.selCoupon = {};
      state.noSelCp = false;
      AddDialog.value.dialogFormVisibles = false;
    };
    return {
      ...toRefs(state),
      AddDialog,
      disedVerifRecord,
      couponLength,
      couponChange,
      clear,
      // 核销
      finishkeep: () => {
        const { cardNo, userId } = state.AddData;
        const userInfo = JSON.parse(localStorage.getItem('user_info') as any);
        CardManageApi.saveVerifierRecord({
          cardNo,
          userId,
          verifierName: userInfo.username,
          ...state.selCoupon,
        }).then((res: any) => {
          Message[res.success ? 'success' : 'warning'](
            `核销${res.message ? '成功' : '失败'}`,
          );
          if (res.success) clear();
          emit('back');
        });
      },
      // 只核销优惠券
      onlyWriteOFf: () => {
        state.noSelCp = !state.selCoupon.userCouponId ? true : false;
        if (!state.noSelCp)
          CardManageApi.VerifierCoupon(state.selCoupon).then((res: any) => {
            Message[res.success ? 'success' : 'warning'](
              `核销优惠券${res.message ? '成功' : '失败'}`,
            );
            if (res.success) clear();
          });
      },
      // 初始化 查询核销详情
      dialogInit: (data: { cardNo: any; userId: any }) => {
        AddDialog.value.open();
        const { cardNo, userId } = data;
        CardManageApi.findVerifierInfo({ cardNo, userId }).then(res => {
          state.AddData = {
            ...res.data,
            cardNo,
            userId,
            nowDiscount: Number(res.data.nowDiscount),
            minDiscount: Number(res.data.minDiscount),
          };
        });
      },
    };
  },
});
