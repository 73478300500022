import request from '../utils/request';
// 账号管理
export const AccountApi = {
  findPage(params: any) {
    return request.post('/scAdmin/admin/user/findPage', params); //账号分页查询
  },
  enable(id: string) {
    return request.get(`/scAdmin/admin/user/enable/${id}`); //账号启用
  },
  disable(id: string) {
    return request.get(`/scAdmin/admin/user/disable/${id}`); //账号停用
  },
  deleteById(id: string) {
    return request.get(`/scAdmin/admin/user/delete/${id}`); //账号删除
  },
  addAccount(params: any) {
    return request.post('/scAdmin/admin/user/add', params); //账号添加
  },
  editAccount(params: any) {
    return request.post('/scAdmin/admin/user/edit', params); //账号编辑
  },
  resetPWd(params: any) {
    return request.post('/scAdmin/admin/user/resetPwd', params); //账号重置
  },
};
// 角色管理
export const RoleApi = {
  findPage(params: any) {
    return request.post('/scAdmin/admin/role/findPage', params); //角色分页查询
  },
  editRole(params: any) {
    return request.post('/scAdmin/admin/role/edit', params); //角色 修改
  },
  addRole(params: any) {
    return request.post('/scAdmin/admin/role/add', params); //角色 新增
  },
  deleteById(id: string) {
    return request.get(`/scAdmin/admin/role/delete/${id}`); //角色 删除
  },
};
// 菜单管理
export const MenuApi = {
  findTree(params: any) {
    return request.post('/scAdmin/admin/menu/tree', params); //分页
  },
  addMenu(params: any) {
    return request.post('/scAdmin/admin/menu/add', params); //添加
  },
  editMenu(params: any) {
    return request.put('/scAdmin/admin/menu/edit', params); //编辑
  },
  getMenuInfo(id: string) {
    return request.get(`/scAdmin/admin/menu/info/${id}`); //详情
  },
  deleteById(id: string) {
    return request.delete(`/scAdmin/admin/menu/delete/${id}`); //详情
  },
};
// 操作日志
export const RecordApi = {
  searchCreatedByOperator(params: any) {
    return request.post('/scAdmin/admin/syslog/searchCreateByOperator', params); //操作人查询
  },
  searchSyslog(params: any) {
    return request.post('/scAdmin/admin/syslog/searchSysLog', params); //查询日志
  },
};
