

































































import { MertmentApi } from '@/api/mertmanget';
import {
  defineComponent,
  nextTick,
  onMounted,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import { Nonchinese } from '@/utils/tools';
import SelectShop from '@/components/SelectShop.vue';
import { AddParam } from './index';
import { WriteoffApi } from '@/api/opermanage';
import { Message } from 'element-ui';
import { copy, isNumber } from '@/constants/mertmanget';
export default defineComponent({
  components: {
    SelectShop,
  },
  props: {
    isAdd: {
      type: Boolean,
    },
  },
  setup(props: any, { emit }) {
    const AddDialog = ref();
    const Selectshop = ref();
    const AddForm = ref();
    const AddData = reactive({} as AddParam);
    const state = reactive({
      AddData,
      MerchantList: [],
      isshowshop: false,
      Formrule: {
        merchantId: [
          { required: true, message: '请选择商户', trigger: 'change' },
        ],
        realName: [
          { required: true, message: '请输入卡名称', trigger: 'blur' },
        ],
        username: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          { min: 6, max: 12, message: '长度在6-12字符之间', trigger: 'blur' },
        ],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      },
    });
    onMounted(() => {
      // 商户下拉列表
      MertmentApi.findPage({ pageSize: 9999, currentPage: 1, status: 0 }).then(
        res => {
          state.MerchantList = res.data.records;
        },
      );
    });
    return {
      ...toRefs(state),
      Nonchinese,
      AddDialog,
      Selectshop,
      AddForm,
      isNumber,
      dialoginit: (obj: any) => {
        AddDialog.value.open();
        const AddInfo: AddParam = obj;
        nextTick(() => {
          AddForm.value.clearValidate();
        });
        state.AddData = AddInfo ? copy(AddInfo) : ({} as AddParam);
        if (state.AddData.id) {
          // 加载店铺
          const params = {
            merchantId: state.AddData.merchantId,
            value: state.AddData.storeIds,
          };
          nextTick(() => {
            Selectshop.value.init(params);
          });
        } else {
          nextTick(() => {
            Selectshop.value.init();
          });
        }
      },
      finishkeep: (isAdd: boolean) => {
        AddForm.value.validate((val: boolean) => {
          if (!val) return;
          if (state.AddData.storeIds?.length === 0) {
            Message.warning('至少选择一家门店');
            return;
          }
          state.AddData.merchantName = (state.MerchantList.find(
            i => (i as any).id === state.AddData.merchantId,
          ) as any).name;
          const msg = isAdd ? '添加成功' : '修改成功';
          const postApi = isAdd ? WriteoffApi.add : WriteoffApi.edit;
          postApi(state.AddData).then(() => {
            Message.success(msg);
            emit('confirm');
            AddDialog.value.dialogFormVisibles = false;
          });
        });
      },
      mernameChange: (val: string) => {
        state.AddData.storeIds = [];
        if (val) {
          const params = {
            merchantId: state.AddData.merchantId,
            value: state.AddData.storeIds,
          };
          Selectshop.value.init(params);
        }
      },
    };
  },
});
