















































































































































































































































































































































import { MertmentApi } from '@/api/mertmanget';
import { throttle } from '@/utils/tools';
import { CouponmageApi, OpermageApi, TabApi } from '@/api/opermanage';
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
  watch,
} from '@vue/composition-api';
import {
  AddParam,
  SecondCardText,
  MasterCardText,
  ValidityType,
} from './index';
import { CouponText } from '../Coupon/index';
import { Message } from 'element-ui';
import { copy, scale, Yscale, NoNum } from '@/constants/mertmanget';
import router from '@/router';
export default defineComponent({
  setup() {
    const shardcardform = ref();

    const Adddata = reactive({
      cardCouponRequestList: [
        {
          couponId: '',
          couponType: '',
          couponNum: '',
          couponName: '',
        },
      ],
      initDiscountRequest: {
        initDiscount: 0,
        lowerDiscount: 0,
        effectQuota: 0,
      },
      minDiscountRequest: {
        minDiscount: 0,
        lowerDiscount: 0,
        effectQuota: 0,
      },
      timeType: '3',
    } as AddParam);
    const initDiscount = (rule: any, value: any, callback: any) => {
      if (value <= shardcardform.value.model.minDiscountRequest.minDiscount) {
        callback(new Error(`初始折扣应大于最低折扣`));
      } else if (value <= 0 || value > 10) {
        callback(new Error(`折扣应大于0小于10`));
      }
      callback();
    };
    const validateViceTime = (rule: any, value: number, callback: any) => {
      if (Adddata.mainTime && value > Adddata.mainTime) {
        callback(new Error(`副卡有效期不能 大于主卡有效期`));
      }
      callback();
    };
    const validateMainTime = (rule: any, value: number, callback: any) => {
      if (Adddata.viceTime && value < Adddata.viceTime) {
        callback(new Error(`主卡有效期不能小于副卡有效期`));
      }
      callback();
    };
    const minDiscount = (rule: any, value: any, callback: any) => {
      if (value >= shardcardform.value.model.initDiscountRequest.initDiscount) {
        callback(new Error(`最低折扣应小于最低折扣`));
      } else if (value <= 0 || value > 10) {
        callback(new Error(`折扣应大于0小于10`));
      }
      callback();
    };
    const Discount = (rule: any, value: any, callback: any) => {
      if (value <= 0 || value > 10) {
        callback(new Error(`折扣应大于0小于10`));
      }
      callback();
    };

    const state = reactive({
      MerchantList: [],
      CardLabelList: [],
      Adddata,
      ValidityType,
      MasterCardList: Object.keys(MasterCardText).map(key => ({
        label: (MasterCardText as any)[key],
        value: Number(key),
      })),
      SecondCardList: Object.keys(SecondCardText).map(key => ({
        label: (SecondCardText as any)[key],
        value: Number(key),
      })),
      CouponList: [],
      Formrule: {
        merchantId: [
          { required: true, message: '请选择商户', trigger: 'change' },
        ],
        cardName: [
          { required: true, message: '请输入卡名称', trigger: 'blur' },
          { min: 3, max: 20, message: '长度在3-20个字符之间', trigger: 'blur' },
        ],
        initDiscountRequest: {
          initDiscount: [
            { required: true, message: '请输入主卡初始折扣', trigger: 'blur' },
            { validator: initDiscount, trigger: 'blur' },
          ],
          lowerDiscount: [
            {
              required: true,
              message: '请输入主卡单次使用降低折扣',
              trigger: 'blur',
            },
            { validator: Discount, trigger: 'blur' },
          ],
          effectQuota: [
            { required: true, message: '请输入主卡生效限额', trigger: 'blur' },
          ],
        },
        minDiscountRequest: {
          minDiscount: [
            { required: true, message: '请输入副卡最低折扣', trigger: 'blur' },
            { validator: minDiscount, trigger: 'blur' },
          ],
          lowerDiscount: [
            {
              required: true,
              message: '请输入副卡单次使用降低折扣',
              trigger: 'blur',
            },
            { validator: Discount, trigger: 'blur' },
          ],
          effectQuota: [
            { required: true, message: '请输入副卡生效限额', trigger: 'blur' },
          ],
        },
        mainTime: [
          { required: true, message: '请输入主卡有效期', trigger: 'blur' },
          { validator: validateMainTime, trigger: 'blur' },
        ],
        viceTime: [
          { required: true, message: '请输入副卡有效期', trigger: 'blur' },
          { validator: validateViceTime, trigger: 'blur' },
        ],
        timeType: [
          { required: true, message: '请选择有效期类型', trigger: 'change' },
        ],
        viceNum: [
          { required: true, message: '请输入副卡数量', trigger: 'blur' },
        ],
        sellMoney: [
          { required: true, message: '请输入售卡金额', trigger: 'blur' },
        ],
      },
      shareCardId: '',
    });
    const isEdit = computed(() => {
      if (state.shareCardId) return true;
      else return false;
    });
    const couponIdChange = (row: any) => {
      console.log(row);
      const parent = state.CouponList.find(
        i => (i as any).id === row.couponId,
      ) as any;
      row.couponType = parent.couponType;
      row.couponName = (CouponText as any)[parent.couponType];
      console.log(row.couponName, 'row.couponName');
      return row;
    };
    //从return提取出来  再加上节流  防止用户连续点击
    const keepdata = throttle(() => {
      let idx = 1;
      shardcardform.value.validate((val: boolean) => {
        if (!val) return;
        state.Adddata.cardCouponRequestList?.map(
          (item: { couponId: any; couponNum: any }) => {
            if (!item.couponId || !item.couponNum) {
              Message.warning('请选择优惠券并输入数量');
              idx = 2;
              return true;
            }
          },
        );
        if (idx === 2) {
          return;
        }
        const params = copy(state.Adddata);
        params.cardCouponRequestList.map((item: any) => {
          delete item.couponName;
        });
        params.sellMoney = Yscale(params.sellMoney);
        params.minDiscountRequest.effectQuota = Yscale(
          params.minDiscountRequest.effectQuota,
        );
        params.initDiscountRequest.effectQuota = Yscale(
          params.initDiscountRequest.effectQuota,
        );
        const postApi = state.shareCardId ? OpermageApi.edit : OpermageApi.add;
        const msg = state.shareCardId ? '修改成功' : '添加成功';
        postApi(params).then(() => {
          Message.success(msg);
          router.go(-1);
        });
      });
    }, 2000);

    const addCoupon = () => {
      state.Adddata.cardCouponRequestList.push({
        couponId: '',
        couponType: '',
        couponNum: '',
        couponName: '',
      });
    };
    const delCoupon = (idx: string) => {
      if (state.Adddata.cardCouponRequestList.length === 1) {
        Message.warning('最少保留一个优惠券配置项');
        return;
      }
      state.Adddata.cardCouponRequestList.splice(idx, 1);
    };
    const calcel = () => {
      router.go(-1);
    };
    onMounted(() => {
      // 商户下拉列表
      const routers: any = router;
      state.shareCardId = routers.history.current.params.id;
      if (state.shareCardId) {
        OpermageApi.findById(state.shareCardId).then(res => {
          state.Adddata = res.data as AddParam;
          state.Adddata = {
            id: res.data.id,
            merchantId: res.data.merchantId,
            cardLabelId: res.data.cardLabelId,
            cardName: res.data.cardName,
            timeType: res.data.timeType || '3',
            initDiscountRequest: {
              ...res.data.initDiscountRequest,
              effectQuota: scale(res.data.initDiscountRequest.effectQuota),
            },
            minDiscountRequest: {
              ...res.data.minDiscountRequest,
              effectQuota: scale(res.data.minDiscountRequest.effectQuota),
            },
            mainTime: res.data.mainTime,
            viceTime: res.data.viceTime,
            viceNum: res.data.viceNum,
            sellMoney: scale(res.data.sellMoney),
            cardCouponRequestList: res.data.couponList.map((item: any) => ({
              couponId: item.couponId,
              couponType: item.couponType,
              couponNum: item.couponNum,
              couponName: (CouponText as any)[item.couponType],
            })),
          };
        });
      }
      MertmentApi.findPage({ pageSize: 9999, currentPage: 1, status: 0 }).then(
        res => {
          state.MerchantList = res.data.records;
        },
      );
      // 页签管理
      TabApi.findPage({ pageSize: 9999, currentPage: 1, status: 0 }).then(
        res => {
          state.CardLabelList = res.data.records;
        },
      );

      CouponmageApi.findPage({
        pageSize: 9999,
        currentPage: 1,
        status: '0',
        couponType: 'ALL',
      }).then(res => {
        state.CouponList = res.data.records;
        console.log(state.CouponList, 'CouponList');
      });
    });
    // watch监听有效期单位(新加)
    watch(
      () => state.Adddata.timeType,
      value => {
        if (value === '月') {
          if (state.Adddata.mainTime && state.Adddata.mainTime > 24) {
            state.Adddata.mainTime = 24;
          }
          if (state.Adddata.viceTime && state.Adddata.viceTime > 24) {
            state.Adddata.viceTime = 24;
          }
        }
      },
      {
        deep: true,
      },
    );
    // watch(
    //   () => state.Adddata.mainTime,
    //   value => {
    //     // console.log(newMainTime, newViceTime, '898');
    //     if (
    //       state.Adddata.viceTime &&
    //       state.Adddata.mainTime &&
    //       state.Adddata.viceTime > value
    //     ) {
    //       state.Adddata.viceTime = value;
    //     }
    //   },
    //   {
    //     deep: true,
    //   },
    // );
    // watch(
    //   () => state.Adddata.viceTime,
    //   value => {
    //     console.log(value, '898');
    //     if (
    //       state.Adddata.viceTime &&
    //       state.Adddata.mainTime &&
    //       value > state.Adddata.mainTime
    //     ) {
    //       state.Adddata.viceTime = state.Adddata.mainTime;
    //       console.log(state.Adddata.viceTime, '444');
    //     }
    //   },
    //   {
    //     deep: true,
    //   },
    // );
    return {
      ...toRefs(state),
      scale,
      couponIdChange,
      CouponText,
      addCoupon,
      delCoupon,
      shardcardform,
      isEdit,
      calcel,
      NoNum,
      keepdata,
    };
  },
});
