



















































































import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import pageSizeChange from '@/mixin/pagesize';
import { SearchParam } from './index';
import WriteOff from './writeOff.vue';
import { CardManageApi } from '@/api/usermanage';
import { Message } from 'element-ui';
export default defineComponent({
  mixins: [pageSizeChange],
  components: {
    WriteOff,
  },
  setup() {
    const SearchParams = reactive({} as SearchParam);
    const pageTable = ref();
    const writeOffDialog = ref();
    const state = reactive({
      SearchParams,
      DateTime: [],
    });
    const handleSearch = () => {
      pageTable.value.onLoad();
    };
    onMounted(() => {
      handleSearch();
    });
    return {
      ...toRefs(state),
      handleSearch,
      pageTable,
      writeOffDialog,
      findPage: CardManageApi.findMainCard,
      DateChange: (date: (string | undefined)[]) => {
        state.SearchParams.expireStartTime = date?.[0];
        state.SearchParams.expireEndTime = date?.[1];
      },
      clear: () => {
        state.SearchParams = {};
        state.DateTime = [];
        // const a:boolean = true;
        // let b:number = 0;
        // a && (b = 4);
        // console.log(b); //修改有报错
      },
      writeOff: (row: { cardNo: any; userId: any }) => {
        const { cardNo, userId } = row;
        writeOffDialog.value.dialogInit({ cardNo, userId });
      },
      exportMCard: () => {
        CardManageApi.exportMainCard(state.SearchParams).then(() => {
          Message.success('导出成功');
        });
      },
    };
  },
});
