<template>
  <Dialog
    ref="cardDialog"
    title="生成台卡"
    width="600"
    :showconfirm="false"
    @cancel="closeDialog"
  >
    <template slot="body">
      <div class="qrcode">
        <div style="margin-top: 20px;margin-bottom:10px">
          <el-radio-group v-model="buttonVal" size="small" @change="changType">
            <el-radio-button label="生成核销码"></el-radio-button>
            <el-radio-button label="生成推广码"></el-radio-button>
          </el-radio-group>
        </div>
        <template v-if="buttonVal === '生成推广码'">
          <el-form
            :model="formParms"
            label-width="115px"
            :rules="rules"
            ref="ruleForm"
          >
            <el-form-item label="选择共享卡" prop="cardId">
              <el-select
                size="small"
                @change="changeCard"
                v-model="formParms.cardId"
              >
                <el-option
                  :label="item.cardName"
                  :value="item.id"
                  :key="item.id"
                  v-for="item in shareCards"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="选择成员(选填)">
              <el-select size="small" v-model="formParms.distributionId">
                <el-option
                  :value="item.id"
                  :key="item.id"
                  v-for="item in users"
                  :label="item.realName"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button
                @click="generatePromotionUrl()"
                type="primary"
                size="mini"
                >确定</el-button
              >
            </el-form-item>
          </el-form>
        </template>
        <div v-if="showQr" v-loading="loading">
          <img :src="imgData" />
          <el-row>核销端入口二维码，选择图片大小</el-row>
          <el-row type="flex" justify="center">
            <el-radio-group v-model="imgsize" @change="getCode()">
              <el-radio label="300">300*300px</el-radio>
              <el-radio label="500">500*500px</el-radio>
              <el-radio label="1000">1000*1000px</el-radio>
            </el-radio-group>
          </el-row>
          <el-row type="flex" justify="center" style="margin-left:0">
            <el-link type="primary" @click="createPicture">保存图片</el-link>
          </el-row>
        </div>
      </div>
    </template>
  </Dialog>
</template>
<script>
import { ShopmageApi } from '@/api/opermanage';
import { downpicture } from '@/utils/tools';
export default {
  props: {
    cardInfo: {
      type: Object,
    },
  },
  data() {
    return {
      loading: true,
      showQr: true,
      users: [],
      cardId: '',
      distributionId: '',
      shareCards: [],
      buttonVal: '生成核销码',
      formParms: {
        cardId: '',
        distributionId: '',
      },
      imgData: '',
      imgsize: '300',
      downloadimg: '',
      rules: {
        cardId: [{ required: true, message: '请选择', trigger: 'change' }],
      },
    };
  },
  created() {
    this.getCode();
    this.dialoginit();
  },
  methods: {
    changeCard(val) {
      const { id, merchantId } = this.shareCards.find(item => {
        return item.id === val;
      });
      this.findUserByMerchant({
        shareCardId: id,
        merchantId,
        storeId: this.cardInfo.id,
      });
    },
    createPicture() {
      downpicture(this.imgData);
    },
    dialoginit() {
      this.$nextTick(() => {
        this.$refs.cardDialog.open();
      });
    },
    closeDialog() {
      this.$refs.cardDialog.dialogFormVisibles = false;
      this.$emit('cancel');
    },
    async changType(val) {
      this.imgData = '';
      if (val === '生成推广码') {
        this.showQr = false;
        const res = await ShopmageApi.findShareCardByStore(this.cardInfo.id);
        this.shareCards = res.data;
        // this.findUserByMerchant();
      } else {
        this.getCode();
        this.showQr = true;
      }
    },
    async findUserByMerchant(params) {
      const res = await ShopmageApi.findUserByMerchant(params);
      this.users = res.data;
    },
    generatePromotionUrl() {
      this.loading = true;
      this.$refs['ruleForm'].validate(async valid => {
        if (valid) {
          const params = {
            ...this.cardInfo,
            ...this.formParms,
            width: this.imgsize,
            height: this.imgsize,
          };
          console.log(7333, params);
          const res = await ShopmageApi.generatePromotionUrl(params);
          this.showQr = true;
          console.log(72222, res);
          this.imgData =
            'data:image/png;base64,' +
            btoa(
              new Uint8Array(res).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                '',
              ),
            );
          this.loading = false;
        } else {
          return false;
        }
      });
    },
    getCode() {
      this.loading = true;
      const params = {
        ...this.cardInfo,
        width: this.imgsize,
        height: this.imgsize,
      };
      ShopmageApi.generateUrl(params).then(res => {
        this.imgData =
          'data:image/png;base64,' +
          btoa(
            new Uint8Array(res).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              '',
            ),
          );
        this.loading = false;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.qrcode {
  text-align: center;
  img {
    width: 300px;
    height: 300px;
  }
  .el-row {
    margin-top: 10px;
  }
}
</style>
