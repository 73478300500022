<template>
  <div>
    <!-- 广告管理 -->
    <ByViewQuery :handleSearch="handleSearch" :clear="clear">
      <template slot="search">
        <SearchItem label="商户名称" :colspan="7">
          <el-input size="small" v-model="SearchParams.merchantName" />
        </SearchItem>
        <SearchItem label="状态" :colspan="7">
          <el-select size="small" v-model="SearchParams.operateStatus">
            <el-option
              v-for="item in StatusList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </SearchItem>
      </template>
      <template slot="add_operation">
        <el-button type="primary" size="small" @click="Addadvert"
          >添&nbsp;加</el-button
        >
      </template>
      <template slot="table">
        <!-- <el-row style="margin-bottom:20px">
          <el-radio-group size="small">
            <el-radio-button size="small" label="1">轮播图</el-radio-button>
            <el-radio-button size="small" label="2">卡池</el-radio-button>
          </el-radio-group>
        </el-row> -->
        <div class="table">
          <PaginationTable
            ref="pageTable"
            :auto-load="false"
            :row-class-name="tableRowClassName"
            :api="findPage"
            :api-params="SearchParams"
            :pagination="false"
            row-key="id"
          >
            <el-table-column label="排序">
              <template>
                <i class="el-icon-d-caret move"></i>
              </template>
            </el-table-column>
            <el-table-column prop="uploadImg" label="图片" width="200">
              <template slot-scope="scope">
                <img :src="imghost + scope.row.uploadImg" width="200" />
              </template>
            </el-table-column>
            <el-table-column
              prop="merchantName"
              label="商户名称"
            ></el-table-column>
            <el-table-column prop="theme" label="主题"></el-table-column>
            <el-table-column
              prop="cardId"
              label="URL或路径参数"
            ></el-table-column>
            <el-table-column prop="pushTime" label="上架日期"></el-table-column>
            <el-table-column prop="pullTime" label="下架日期"></el-table-column>
            <el-table-column prop="operateStatus" label="状态">
              <template slot-scope="scope">
                {{ StatusText[scope.row.operateStatus] }}
              </template>
            </el-table-column>
            <el-table-column label="操作" width="180">
              <template slot-scope="scope">
                <span
                  class="text-oper"
                  v-for="item in scope.row.action"
                  :key="item.type"
                  @click="handleAction(item, scope.row)"
                  >{{ item.text }}</span
                >
              </template>
            </el-table-column>
          </PaginationTable>
        </div>
      </template>
    </ByViewQuery>
    <Add ref="dialogVisible" :isAdd="isAdd" @confirm="CloseDialog" />
  </div>
</template>
<script>
import { defineComponent, reactive, ref, toRefs } from '@vue/composition-api';
import { StatusText, getActiityActions } from './index';
import Sortable from 'sortablejs';
import { MessageBox, Message } from 'element-ui';
import Add from './add.vue';
import { AdvermageApi } from '@/api/advermanage';
export default defineComponent({
  components: { Add },
  setup() {
    const pageTable = ref();
    const dialogVisible = ref(null);
    const state = reactive({
      SearchParams: {
        merchantName: '',
        operateStatus: '',
      },
      isAdd: true,
      StatusList: Object.keys(StatusText).map(key => ({
        label: StatusText[key],
        value: key,
      })),
      StatusText,
      imghost: 'http://ka-img-dev.billbear.cn/',
      tableData: [],
    });
    return {
      ...toRefs(state),
      pageTable,
      dialogVisible,
    };
  },
  methods: {
    async findPage(params) {
      delete params.currentPage;
      delete params.pageSize;
      const res = await AdvermageApi.search(params);
      res.data = res.data.map(item => ({
        ...item,
        action: getActiityActions(item),
      }));

      this.tableData = res.data;
      return res;
    },
    // 创建sortable实例
    initSortable() {
      const that = this;
      // 获取表格row的父节点
      const ele = this.$refs.pageTable.$el.querySelector(
        '.el-table__body > tbody',
      );
      // 创建拖拽实例
      Sortable.create(ele, {
        animation: 150, //动画
        handle: '.move', //指定拖拽目标，点击此目标才可拖拽元素(此例中设置操作按钮拖拽)
        dragClass: 'dragClass', //设置拖拽样式类名
        ghostClass: 'ghostClass', //设置拖拽停靠样式类名
        chosenClass: 'chosenClass', //设置选中样式类名
        // 开始拖动事件
        // onStart: () => {
        //   console.log('开始拖动');
        // },
        // 结束拖动事件
        onEnd: e => {
          // console.log(
          //   '结束拖动',
          //   `拖动前索引${e.oldIndex}---拖动后索引${e.newIndex}`,
          // );
          const tableDatas = JSON.parse(JSON.stringify(that.tableData));
          const newrow = tableDatas[e.newIndex];
          const oldrow = tableDatas[e.oldIndex];

          tableDatas[e.newIndex] = oldrow;
          tableDatas[e.oldIndex] = newrow;
          const params = {
            ids: tableDatas.map(item => {
              return item.id;
            }),
          };
          AdvermageApi.sortUpdate(params).then(() => {
            Message.success('修改排序成功');
            this.findPage(this.SearchParams);
          });
          // console.log('当前行数据',e,res)
        },
      });
    },
    // 设置表格row的class
    tableRowClassName(row) {
      if (row.disabled) {
        return 'disabled';
      }
      return '';
    },
    handleSearch() {
      this.$refs.pageTable.onLoad();
      this.initSortable();
    },
    clear() {
      this.SearchParams = {};
    },
    handleAction(item, row) {
      if (item.type != 'EDIT') {
        MessageBox.confirm(`是否确认${item.text}？`, {
          showClose: false,
          center: true,
        })
          .then(() => {
            AdvermageApi.deleteById(row.id).then(() => {
              Message.success(`${item.text}成功`);
              this.handleSearch();
            });
          })
          .catch(() => {
            Message('已取消');
          });
      } else {
        this.isAdd = false;
        this.$refs.dialogVisible.dialoginit(row);
      }

      // const map =  {
      //   [StatusText.UP]: () => {},
      //   [StatusText.DOWN]: () => {},
      //   [StatusText.EDIT]: () => {},
      //   [StatusText.DELETE]: () => {},
      //  }
      // const func = map[type];
      // typeof func === 'function' && func();
    },
    Addadvert() {
      this.isAdd = true;
      this.$refs.dialogVisible.dialoginit();
    },
    CloseDialog() {
      this.handleSearch();
    },
  },
  mounted() {
    this.handleSearch();
  },
});
</script>
<style lang="scss">
// 拖拽
.dragClass {
  background: rgba($color: #41c21a, $alpha: 0.5) !important;
}
// 停靠
.ghostClass {
  background: rgba($color: #6cacf5, $alpha: 0.5) !important;
}
// 选择
.chosenClass:hover > td {
  background: rgba($color: #f56c6c, $alpha: 0.5) !important;
}
.el-table i {
  font-size: 21px;
}
</style>
