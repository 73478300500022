










































































































import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import { SearchParam, StatusText } from './index';
import { MessageBox } from 'element-ui';
import { UsermageApi } from '@/api/usermanage';
import { Message } from 'element-ui';
import pageSizeChange from '@/mixin/pagesize';
import { Snake, Horse, Animal } from '../demo';
export default defineComponent({
  mixins: [pageSizeChange],
  setup() {
    const SearchParams = reactive({ status: '' } as SearchParam);
    const ReasonDialog = ref();
    const pageTable = ref();
    const state = reactive({
      SearchParams,
      StatusList: Object.keys(StatusText).map(key => ({
        label: (StatusText as any)[key],
        value: key,
      })),
      StatusText,
      DateTime: [],
      forzen: {
        id: '',
        status: '',
        remark: '', //冻结原因
      },
    });
    const handleSearch = () => {
      pageTable.value.onLoad();
    };
    onMounted(() => {
      handleSearch();
      const sam = new Snake('Sammy the Python');
      const tom: Animal = new Horse('Tommy the Palomino');

      sam.move();
      tom.move(34);
    });
    return {
      ...toRefs(state),
      ReasonDialog,
      pageTable,
      handleSearch,
      findPage: UsermageApi.findPage,
      DateChange: (date: (string | undefined)[]) => {
        state.SearchParams.registrationStartTime = date?.[0];
        state.SearchParams.registrationEndTime = date?.[1];
      },
      clear: () => {
        state.SearchParams = { status: '' };
        state.DateTime = [];
        // const a:boolean = true;
        // let b:number = 0;
        // a && (b = 4);
        // console.log(b); //修改有报错
      },
      // 冻结 or 取消冻结
      Isforzen: (row: { status: string; id: any }) => {
        if ((state.StatusText as any)[row.status] === '已冻结') {
          MessageBox.confirm('是否确认取消冻结？', {
            distinguishCancelAndClose: true,
            showClose: false,
            center: true,
          })
            .then(() => {
              const params = {
                id: row.id,
                status: '0',
              };
              UsermageApi.userFreeze(params).then(() => {
                Message.success('取消冻结成功');
                handleSearch();
              });
            })
            .catch(() => {
              Message.info('取消操作了');
            });
        } else {
          const el: any = ReasonDialog.value;
          state.forzen.id = row.id;
          state.forzen.status = '2';
          el.open();
        }
      },
      CloseDialog: () => {
        state.forzen = {
          id: '',
          status: '',
          remark: '', //冻结原因
        };
      },
      UpDialog: () => {
        if (!state.forzen.remark) {
          Message.warning('请输入冻结原因');
          return;
        }
        const params = {
          ...state.forzen,
        };
        UsermageApi.userFreeze(params).then(() => {
          Message.success('冻结成功');
          handleSearch();
          ReasonDialog.value.dialogFormVisibles = false; //关闭弹框
        });
      },
    };
  },
  // methods: {
  //   handleSearch() {
  //     this.$nextTick(() => {
  //       const el: any = this.$refs.pageTable;
  //       el.onLoad();
  //     });
  //   },
  // },
});
