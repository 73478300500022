

























































































import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import { CardSalesParam, CardStatusText } from './index';
import { BusinessApi } from '@/api/opermanage';
import { amountToFixedByDecimalDigits } from '@/utils/tools';
import router from '@/router';
export default defineComponent({
  setup() {
    const SearchParams = reactive({ cardStatus: 'ALL' } as CardSalesParam);
    const pageTable = ref();
    const state = reactive({
      SearchParams,
      CardStutsList: Object.keys(CardStatusText).map(key => ({
        label: (CardStatusText as any)[key],
        value: key,
      })),
      CardStatusText,
      DateTime: [],
      pageSize: 50,
      currentPage: 1,
      tableData: [],
      StoreList: [],
      storeId: '',
    });
    const handleSearch = () => {
      pageTable.value.onLoad();
    };
    const exports = () => {
      BusinessApi.salesCardexport(state.SearchParams);
    };
    onMounted(() => {
      const routers: any = router;
      state.SearchParams.merchantId = routers.history.current.params.id;
      handleSearch();
      BusinessApi.salesStoreList({
        merchantId: state.SearchParams.merchantId,
      }).then(res => {
        state.StoreList = res.data;
      });
    });
    return {
      ...toRefs(state),
      pageTable,
      findPage: BusinessApi.salesCardfindPage,
      handleSearch,
      exports,
      amountToFixedByDecimalDigits,
      DateChange: (date: (string | undefined)[]) => {
        state.SearchParams.startTime = date?.[0];
        state.SearchParams.endTime = date?.[1];
      },
      clear: () => {
        state.SearchParams = {
          cardStatus: 'ALL',
          merchantId: state.SearchParams.merchantId,
        };
        state.DateTime = [];
      },
    };
  },
});
