















































































































import LinkButton from '@/components/LinkButton.vue';
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import { SearchParam } from './index';
import { MertmentApi } from '@/api/mertmanget';
import { AccountStatusList, Accountstatus } from '@/constants/mertmanget';
import pageSizeChange from '@/mixin/pagesize';
export default defineComponent({
  mixins: [pageSizeChange],
  components: { LinkButton },
  setup() {
    const SearchParams = reactive({ status: '' } as SearchParam);
    const pageTable = ref();
    const state = reactive({
      SearchParams,
      StatusList: AccountStatusList,
      Accountstatus,
    });
    const handleSearch = () => {
      pageTable.value.onLoad();
    };
    onMounted(() => {
      handleSearch();
    });
    return {
      ...toRefs(state),
      findPage: MertmentApi.findPage,
      handleSearch,
      pageTable,
      clear: () => {
        state.SearchParams = { status: '' };
      },
    };
  },
  methods: {
    // handleSearch() {
    //   this.$nextTick(() => {
    //     const el: any = this.$refs.pageTable;
    //     el.onLoad();
    //   });
    // },
  },
});
