<template>
  <div :class="prefixCls">
    <div ref="editor" class="editor-wrapper" />
    <!-- <div v-html="previewContent" class="editor-preview" /> -->
  </div>
</template>

<script>
import { ACCESS_TOKEN } from '@/constants/storage';
import WEditor from 'wangeditor';
import storage from 'store';

export default {
  name: 'WangEditor',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    prefixCls: {
      type: String,
      default: 'ant-editor-wang',
    },
    value: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value: function(val) {
      if (val !== this.previewContent) {
        this.editor.txt.html(val);
      }
    },
    disabled: function(val) {
      if (val) {
        this.editor.disable();
      } else {
        this.editor.enable();
      }
    },
  },
  data() {
    return {
      editor: null,
      previewContent: null,
    };
  },
  mounted() {
    this.initEditor();
  },
  destroyed() {
    this.editor.destroy();
  },
  methods: {
    initEditor() {
      this.editor = new WEditor(this.$refs.editor);

      this.editor.config.height = 300;
      this.editor.config.showFullScreen = false;
      this.editor.config.onchangeTimeout = 300;
      this.editor.config.pasteIgnoreImg = true;
      this.editor.config.zIndex = 100;
      this.editor.config.menus = [
        'head',
        'bold',
        'fontSize',
        'italic',
        'underline',
        'strikeThrough',
        'indent',
        'foreColor',
        'backColor',
        'link',
        'list',
        'justify',
        'image',
        'video',
        'splitLine',
        'undo',
        'redo',
      ];
      this.editor.config.uploadImgMaxSize = 2 * 1024 * 1024; // 2M
      this.editor.config.uploadImgMaxLength = 10; // 最多10张
      this.editor.config.onchange = html => {
        this.previewContent = html;
        this.$emit('change', this.previewContent);
      };
      // 图片上传配置
      this.editor.config.customUploadImg = function(files, insert) {
        Promise.all(
          files.map(async item => {
            const data = new FormData();
            data.append('file', item);
            const result = await fetch('/api/filecenter/upload', {
              method: 'POST',
              headers: {
                accept: '*/*',
                Authorization: 'Bearer ' + storage.get(ACCESS_TOKEN),
              },
              body: data,
            });
            const res = await result.json();
            // console.log(res.domain + '/' + res.fileKey);
            insert(res.domain + '/' + res.fileKey);
          }),
        );
      };
      this.editor.create();
      this.editor.txt.html(this.value);
    },
  },
};
</script>

<style lang="less" scoped>
.ant-editor-wang {
  display: flex;
  .editor-wrapper {
    width: 500px;
    text-align: left;
  }
  .editor-preview {
    width: 375px;
    height: 597px;
    border: 1px solid #ddd;
    margin-left: 20px;
    padding: 0 10px;
    box-sizing: border-box;
    overflow: auto;
  }
}
</style>
