import request from '../utils/request';

export const AdvermageApi = {
  search(params: any) {
    return request.post('/scAdmin/admin/advertisingManager/search', params);
  },
  statusUpdate(params: any) {
    return request.put(
      '/scAdmin/admin/advertisingManager/statusUpdate',
      params,
    ); //上架下架删除
  },
  deleteById(id: string) {
    return request.get(`/scAdmin/admin/advertisingManager/delete/${id}`); //上架下架删除
  },
  add(params: any) {
    return request.post('/scAdmin/admin/advertisingManager/add', params); //广告添加
  },
  edit(params: any) {
    return request.put('/scAdmin/admin/advertisingManager/edit', params); //广告编辑
  },
  sortUpdate(params: any) {
    return request.put('/scAdmin/admin/advertisingManager/sortUpdate', params); //广告拖拽排序
  },
  findShareCardByMerchantId(merchantId: string) {
    return request.get(
      `/scAdmin/admin/shareCard/findShareCardByMerchantId/${merchantId}`,
    ); //获取商户下广告新增共享卡列表
  },
};
