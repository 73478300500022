
































































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import { SearchParam } from './index';
import { ShopmageApi } from '@/api/opermanage';
import Add from './add.vue';
import { Message, MessageBox } from 'element-ui';
import CodeCard from './codecard.vue';
import { copy } from '@/constants/mertmanget';
import pageSizeChange from '@/mixin/pagesize';
import router from '@/router';
export default defineComponent({
  mixins: [pageSizeChange],
  components: {
    Add,
    CodeCard,
  },
  setup() {
    const SearchParams = reactive({
      merchantId: '',
      merchantName: '',
    } as SearchParam);
    const pageTable = ref();
    const Adddialog = ref();
    const carddialog = ref();
    const state = reactive({
      SearchParams,
      DateTime: [],
      isAdd: false,
      codeCard: false,
      cardInfo: {},
    });
    const handleSearch = () => {
      pageTable.value.onLoad();
    };
    const Nameisdisd = computed(() => {
      if (state.SearchParams.merchantId) return true;
      else return false;
    });
    onMounted(() => {
      const routers: any = router;
      state.SearchParams.merchantId = routers.history.current.params.merchantId;
      state.SearchParams.merchantName =
        routers.history.current.params.merchantName;
      handleSearch();
    });
    return {
      ...toRefs(state),
      pageTable,
      Adddialog,
      carddialog,
      Nameisdisd,
      handleSearch,
      findPage: ShopmageApi.findPage,
      DateChange: (date: (string | undefined)[]) => {
        state.SearchParams.createStartTime = date?.[0];
        state.SearchParams.createEndTime = date?.[1];
      },
      clear: () => {
        const routers: any = router;
        if (routers.history.current.params?.merchantId) {
          state.SearchParams = {
            merchantId: state.SearchParams.merchantId,
            merchantName: state.SearchParams.merchantName,
          };
        } else {
          state.SearchParams = {};
        }
        state.DateTime = [];
      },
      Add: () => {
        state.isAdd = true;
        Adddialog.value.dialoginit();
      },
      CloseDialog: () => {
        handleSearch();
      },
      edit: (row: any) => {
        state.isAdd = false;
        Adddialog.value.dialoginit(copy(row));
      },
      del: (row: any) => {
        MessageBox.confirm('是否确认删除门店？', {
          distinguishCancelAndClose: true,
          showClose: false,
          center: true,
        })
          .then(() => {
            ShopmageApi.delete(row.id).then(() => {
              Message.success('删除成功');
              handleSearch();
            });
          })
          .catch(() => {
            Message.info('取消操作了');
          });
      },
      disabled: (row: { id: any; status: string }) => {
        const msg = row.status === '0' ? '禁用' : '启用';
        MessageBox.confirm(`是否确认${msg}该门店？`, {
          distinguishCancelAndClose: true,
          showClose: false,
          center: true,
        })
          .then(() => {
            ShopmageApi.editStoreStatus({
              storeId: row.id,
              storeStatus: row.status === '0' ? '2' : '0',
            }).then(() => {
              Message.success(`${msg}成功`);
              handleSearch();
            });
          })
          .catch(() => {
            Message.info('取消操作了');
          });
      },
      // 生成台卡
      madecard: (row: any) => {
        state.codeCard = true;
        state.cardInfo = {
          id: row?.id,
          merchantId: row?.merchantId,
        };
        // carddialog.value.dialoginit();
      },
      closecard: () => {
        state.codeCard = false;
      },
    };
  },
});
