// 基础类型
// let isEdit: boolean = false;
// let phonenum: number = 189;
// let names: string = 'bob';
// console.log(`I,am ${names},and phonenumber is ${phonenum}`);
// const lists: number[] = [1, 2, 3];
// const list: Array<number> = [1, 2, 3];//数组
let X: [string, number]; //元组
X = ['hello', 10];
X[1] = 20;
// 枚举 1、
enum Color {
  Red,
  Green,
  Yellow,
}
let anyColor: Color = Color.Yellow;
console.log('anycolor', anyColor); //2  下标从0开始
// 枚举 2、
enum Colors {
  Red = 1,
  Green,
  Blue,
} //下标改为从1开始
let c: Colors = Colors.Green; //2
console.log('c', c);

// any void
// function warnUser():void {
//     console.log('warn message');

// }
// let unusable:void = undefined;//or null

// object类型
// declare function create(o:object | null):void;
// create({prop: 0});
// create(null);
// 类型断言
const someValue: any = 'this is a string';

const strLength: number = (someValue as string).length;
const strLengths: number = (someValue as string).length;
console.log('strLength', strLength, strLengths);

// 接口 可选属性
export interface Points {
  x?: number;
  y?: number;
}
// 只读属性 readonly
export interface Point {
  readonly x: number;
  readonly y: number;
}

let p1: Point = { x: 10, y: 20 };
console.log('p1', p1);

// 则p1中的x、y值不可修改

// TypeScript具有 ReadonlyArray<T>类型，它与Array<T>相似，只是把所有可变方法
// 去掉了，因此可以确保数组重建后再也不能被修改：
let a: number[] = [1, 2, 3, 4, 5];
let ro: ReadonlyArray<number> = a;
// ro[0] = 12;//error 数组只许可读
a = ro as number[]; //然 用类型断言可重写
ro = [1, 3, 5, 7];
// class 类  extends继承
class Animals {
  move(distanceInMeters = 0) {
    console.log(`Animal moved ${distanceInMeters}m.`);
  }
}

class Dog extends Animals {
  bark() {
    console.log('Woof! Woof!');
  }
}
// dog同时拥有了 Dog和Animal内的所有方法
const dog = new Dog();
dog.bark();
dog.move(10);
dog.bark();

class Pig extends Dog {
  gohome() {
    console.log('gohome');
  }
}
const pig = new Pig();
pig.bark();

// 类的例子 类有3个成员：一个叫做 greeting的属性，一个构造函数constructor和一个 greet方法。
class Greeter {
  greeting: string;
  constructor(message: string) {
    this.greeting = message;
  }
  greet() {
    return 'Hello, ' + this.greeting;
  }
}

let greeter = new Greeter('world');
console.log('greeter', greeter); //Hello, world。

// 包含构造函数
export class Animal {
  name: string;
  constructor(theName: string) {
    this.name = theName;
  }
  move(distanceInMeters = 0) {
    console.log(`${this.name} moved ${distanceInMeters}m.`);
  }
}

export class Snake extends Animal {
  constructor(name: string) {
    super(name);
  }
  move(distanceInMeters = 5) {
    console.log('Slithering...');
    super.move(distanceInMeters);
  }
}

export class Horse extends Animal {
  constructor(name: string) {
    super(name);
  }
  move(distanceInMeters = 45) {
    console.log('Galloping...');
    super.move(distanceInMeters);
  }
}

const sam = new Snake('Sammy the Python');
const tom: Animal = new Horse('Tommy the Palomino');

sam.move();
// result  Slithering...
// demo.ts?6970:92 Sammy the Python moved 5m.
tom.move(34);
// result  Galloping...  先执行本身的，后执行extends(继承)的方法
// demo.ts?6970:92 Tommy the Palomino moved 34m.

// Readonly修饰符
class Octopus {
  readonly name: string;
  readonly numberOfLegs: number = 8;
  constructor(readonly theName: string) {
    this.name = theName;
  }
}
let dad = new Octopus('Man with the 8 strong legs');
console.log(dad.name);
// dad.name = "Man with the 3-piece suit"; // 错误! name 是只读的.

// 公有、私有
class Animal2 {
  public name: string;
  public constructor(theName: string) {
    this.name = theName;
  }
}
