






































































import { FinalmageApi } from '@/api/finalmanage';
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import { Message } from 'element-ui';

export default defineComponent({
  setup() {
    const paysetForm = ref();
    const paySetInfo = reactive({
      mchId: '',
    });
    const state = reactive({
      paySetInfo,
      Formrule: {
        mchId: [
          { required: true, message: '请输入微信支付商户号', trigger: 'blur' },
        ],
        appId: [{ required: true, message: '请输入APPID', trigger: 'blur' }],
        apiV3Key: [
          { required: true, message: '请输入RSA秘钥', trigger: 'blur' },
        ],
        certSerialNo: [
          { required: true, message: '请输入证书序列号', trigger: 'blur' },
        ],
        privateKeyContent: [
          {
            required: true,
            message: '请输入privateKeyContent',
            trigger: 'blur',
          },
        ],
        privateCertContent: [
          {
            required: true,
            message: '请输入privateCertContent',
            trigger: 'blur',
          },
        ],
      },
    });
    const handleSearch = () => {
      FinalmageApi.paysetSearch({}).then(res => {
        state.paySetInfo = res.data ? res.data : {};
      });
    };
    onMounted(() => {
      handleSearch();
    });
    return {
      ...toRefs(state),
      paysetForm,
      keepdata: () => {
        paysetForm.value.validate((val: boolean) => {
          if (!val) return;
          FinalmageApi.paysetSave(state.paySetInfo).then(() => {
            Message.success('保存成功');
            handleSearch();
          });
        });
      },
    };
  },
});
