




































import { defineComponent, reactive, ref, toRefs } from '@vue/composition-api';
import { AccountApi } from '@/api/systemmanage';
import { Message } from 'element-ui';
import { copy } from '@/constants/mertmanget';
export default defineComponent({
  setup(props, { emit }) {
    const rsetpwdDialog = ref();
    const state = reactive({
      formData: {
        userId: '',
        newPassword: '',
        resetpwd: '',
      },
    });
    return {
      ...toRefs(state),
      rsetpwdDialog,
      finishkeep: () => {
        if (!state.formData.newPassword || !state.formData.resetpwd) {
          Message.warning('请输入重置密码');
          return;
        }
        if (state.formData.newPassword.length < 6) {
          Message.warning('密码至少6位');
          return;
        }
        if (state.formData.newPassword != state.formData.resetpwd) {
          Message.warning('请确认新密码与再次输入密码是否一致');
          return;
        }
        const params: any = copy(state.formData);
        delete params.resetpwd;
        AccountApi.resetPWd(params).then(() => {
          Message.success('重置密码成功');
          rsetpwdDialog.value.dialogFormVisibles = false;
          state.formData = {
            userId: '',
            newPassword: '',
            resetpwd: '',
          };
          emit('confirm');
        });
      },
      dialoginit: (id: string) => {
        state.formData = {
          userId: id,
          newPassword: '',
          resetpwd: '',
        };
        rsetpwdDialog.value.open();
      },
    };
  },
});
