


















































































import {
  defineComponent,
  nextTick,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import Upload from '@/components/Upload.vue';
import { AddParam, copy, scale, isNum } from '@/constants/mertmanget';
import { MertmentApi } from '@/api/mertmanget';
import { Message } from 'element-ui';
export default defineComponent({
  components: {
    Upload,
  },
  props: {
    isAdd: {
      type: Boolean,
    },
  },
  setup(props: any, { emit }) {
    const isAdds = props.isAdd;
    const AddData = reactive({} as AddParam);
    const AddDialog = ref();
    const AddForm = ref();
    const state = reactive({
      Formrule: {
        name: [
          { required: true, message: '请输入商户名称', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在2到20个字符内', trigger: 'blur' },
        ],
        shortName: [
          { required: true, message: '请输入商户简称', trigger: 'blur' },
          { min: 2, max: 6, message: '2-6个字符内', trigger: 'blur' },
        ],
        logoImg: [
          { required: true, message: '请添加商户logo', trigger: 'blur' },
        ],
        contacts: [
          { required: true, message: '请输入联系人', trigger: 'blur' },
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/,
            message: '请输入正确的手机号',
            trigger: 'blur',
          },
        ],
        DateTime: [
          { required: true, message: '请选择有效期', trigger: 'blur' },
        ],
        packagePrice: [
          { required: true, message: '请输入套餐金额', trigger: 'blur' },
        ],
      },
      AddData,
      isAdds,
      filetype: ['image/png', 'image/jpg'],
      Extension: ['jpg', 'png'],
    });
    return {
      ...toRefs(state),
      AddForm,
      AddDialog,
      scale,
      isNum,
      DateChange: (date: string[]) => {
        state.AddData.startTime = date?.[0];
        state.AddData.endTime = date?.[1];
      },
      finishkeep: (isAdd: any) => {
        AddForm.value.validate((val: boolean) => {
          if (!val) return;
          const params: any = copy(state.AddData);
          params.packagePrice = Number(params.packagePrice) * 100;
          delete params.DateTime;
          const Apipost = isAdd
            ? MertmentApi.openAccount
            : MertmentApi.editChant;
          const msg = isAdd ? '开户成功' : '修改成功';
          Apipost(params).then(() => {
            Message.success(msg);
            AddDialog.value.dialogFormVisibles = false;
            emit('confirm');
          });
        });
      },
      handleCancel: () => {
        state.AddData.logoImg = '';
      },
      dialoginit: (row: any) => {
        if (row) {
          state.AddData = {
            id: row.id,
            name: row.name,
            shortName: row.shortName,
            logoImg: row.logoImg,
            contacts: row.contacts,
            mobile: row.mobile,
            DateTime: [row.startTime, row.endTime],
            startTime: row.startTime,
            endTime: row.endTime,
            packagePrice: scale(row.packagePrice),
          };
        } else {
          state.AddData = { packagePrice: 0 };
        }
        AddDialog.value.open();
        nextTick(() => {
          AddForm.value.clearValidate();
        });
      },
    };
  },
});
