/**
 *发券 列表搜索参数
 */
export interface SearchParam {
  couponName?: string; // 优惠券名称
  merchantId?: string;
}

/**
 * 补券数据 券状态
 */
export const CouponStatusText = {
  '0': '未使用',
  '1': '已使用',
  '2': '已过期',
  '3': '已作废',
};
