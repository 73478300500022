


















































import {
  defineComponent,
  onMounted,
  reactive,
  toRefs,
} from '@vue/composition-api';
import { Message, MessageBox } from 'element-ui';
import { TabManage } from '@/api/opermanage';
export default defineComponent({
  setup() {
    const state = reactive({
      tableData: [] as any,
      editTab: false,
    });
    const swapItems = (arr: any, index1: any, index2: number) => {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0];
      console.log(arr[index1], arr[index2]);
      const oldsort = arr[index1].sort;
      arr[index1].sort = arr[index2].sort;
      arr[index2].sort = oldsort;
      console.log(arr);

      return arr;
    };
    const handleSearch = () => {
      TabManage.findPage({
        pageSize: 1000,
        currentPage: 1,
      }).then(res => {
        state.tableData = res.data.records;
      });
    };
    onMounted(() => {
      handleSearch();
    });
    return {
      ...toRefs(state),
      swapItems,
      handleSearch,
      add: () => {
        state.tableData.push({
          id: '',
          name: '',
          sort: state.tableData.length + 1,
        });
        console.log(state.tableData);
      },
      keepTab: () => {
        let arr: any[] = [];
        for (let i in state.tableData) {
          if (!state.tableData[i].name) {
            Message.warning('请输入要添加的页签名称');
            return;
          }
          arr.push(state.tableData[i].name);
        }
        const k = arr.map(item => {
          return arr.indexOf(item) != arr.lastIndexOf(item);
        });
        if (k.indexOf(true) != -1) {
          Message.warning(`页签重复，请修改`);
          return;
        }
        if (state.tableData.length > 20) {
          Message.warning('最多添加20个页签');
          return;
        }
        TabManage.save({
          dataList: state.tableData,
        }).then((res: any) => {
          if (res.success) {
            Message.success('保存成功');
            handleSearch();
          } else {
            Message.warning('保存失败');
          }
        });
      },
      cancelKeep: () => {
        handleSearch();
      },
      topOne: (index: number) => {
        if (state.tableData.length > 1 && index !== 0)
          state.tableData = swapItems(state.tableData, index, index - 1);
      },
      downOne: (index: number) => {
        if (state.tableData.length > 1 && index !== state.tableData.length - 1)
          state.tableData = swapItems(state.tableData, index, index + 1);
      },
      delOne: (data: any, index: number) => {
        MessageBox.confirm(
          `是否确认删除“${data.name}”页签，删除后不可恢复，请谨慎操作`,
          {
            showClose: false,
            center: true,
          },
        )
          .then(() => {
            state.tableData.splice(index, 1);
            if (state.tableData.length > 0)
              state.tableData.map((item: { sort: number }, index: number) => {
                item.sort = index + 1;
              });
          })
          .catch(() => {
            Message.info('取消了');
          });
      },
    };
  },
});
