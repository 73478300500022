





































import {
  defineComponent,
  nextTick,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import { Nonchinese } from '@/utils/tools';
// import { AddParam } from './index';
import request from '@/utils/request';
import { Message } from 'element-ui';
import { isNumber } from '@/constants/mertmanget';
export default defineComponent({
  props: {
    isAdd: {
      type: Boolean,
    },
  },
  setup(props: any, { emit }) {
    const AddDialog = ref();
    const AddForm = ref();
    const AddData = reactive({
      availableMerchantUserIds: [],
      merchantUserId: '',
    });
    // const validDays = (rule: any, value: any, callback: any) => {
    //   console.log(74, AddData.newPassword);
    //   if (AddData.newPassword !== value) {
    //     callback(new Error(`密码不一致`));
    //   } else {
    //     callback();
    //   }
    // };
    const state = reactive({
      availableMerchantUsers: [],
      nextLevelRealNames: [],
      AddData,
      MerchantList: [],
      isshowshop: false,
      Formrule: {
        availableMerchantUserIds: [
          {
            type: 'array',
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
      },
    });
    return {
      ...toRefs(state),
      Nonchinese,
      AddDialog,
      AddForm,
      isNumber,
      dialoginit: async (obj: any) => {
        AddData.merchantUserId = obj.id;
        AddDialog.value.open();
        // const AddInfo: AddParam = obj;
        nextTick(() => {
          AddForm.value.resetFields();
        });
        const res = await request.post(
          `/scAdmin/admin/verifierMember/findNextLevel`,
          {
            merchantUserId: obj.id,
            merchantId: obj.merchantId,
          },
        );
        state.nextLevelRealNames =
          (res.data.nextLevelRealNames &&
            res.data.nextLevelRealNames.split(',')) ||
          [];
        if (res.data.availableMerchantUsers) {
          state.availableMerchantUsers = res.data.availableMerchantUsers;
        }
        // state.AddData = AddInfo ? copy(AddInfo) : {};
      },
      finishkeep: () => {
        AddForm.value.validate(async (val: boolean) => {
          if (!val) return;
          await request.post('/scAdmin/admin/verifierMember/addNextLevel', {
            availableMerchantUserIds: AddData.availableMerchantUserIds,
            merchantUserId: AddData.merchantUserId,
          });
          AddForm.value.resetFields();
          Message.success('保存成功');
          emit('confirm');
          AddDialog.value.dialogFormVisibles = false;
        });
      },
    };
  },
});
