





































import {
  defineComponent,
  nextTick,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import { ShopmageApi } from '@/api/opermanage';
export default defineComponent({
  props: {
    value: {
      type: [String, Array],
    },
    merchantName: {
      type: String,
      default: '',
    },
    merchantId: {
      type: String,
      default: '',
    },
  },
  setup(props: any, { emit }) {
    const proany: any = props;
    const multipleTable = ref();
    const state = reactive({
      storeName: '',
      tableData: [],
      proany,
    });
    const gettableData = () => {
      const params = {
        pageSize: 9999,
        currentPage: 1,
        merchantName: state.proany?.merchantName,
        storeName: state.storeName,
        merchantId: state.proany?.merchantId,
        flag: '1',
      };
      ShopmageApi.findPage(params).then(res => {
        state.tableData = res.data.records;
        state.tableData.forEach((item: any) => {
          if (state.proany.value?.length > 0) {
            if (state.proany.value.includes(item.id)) {
              nextTick(() => {
                multipleTable.value.toggleRowSelection(item);
              });
            }
          }
        });
      });
    };
    const init = (params: any) => {
      state.storeName = '';
      if (!params) {
        state.tableData = [];
        return;
      }
      state.proany = params ? params : props;
      gettableData();
    };
    // onMounted(() => {
    //   init("");
    // });
    return {
      ...toRefs(state),
      gettableData,
      multipleTable,
      handleSelectionChange: (arr: any) => {
        const selArr = arr.map((item: { id: any }) => {
          return item.id;
        });
        emit('input', selArr);
        emit('change', selArr);
      },
      init,
    };
  },
});
