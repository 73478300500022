
































































































import DatePicker from '@/components/DatePicker.vue';
import LinkButton from '@/components/LinkButton.vue';
import { MessageBox } from 'element-ui';
import Add from './add.vue';
import { MertmentApi } from '@/api/mertmanget';
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import {
  AccountStatusList,
  SearchParam,
  Accountstatus,
} from '@/constants/mertmanget';
import pageSizeChange from '@/mixin/pagesize';
export default defineComponent({
  mixins: [pageSizeChange],
  components: {
    DatePicker,
    LinkButton,
    Add,
  },
  setup() {
    const dialogVisible = ref(null);
    const pageTable = ref();
    const SearchParams = reactive({ status: '' } as SearchParam);
    const state = reactive({
      AccountStatusList,
      Accountstatus,
      SearchParams,
      isAdd: false,
    });
    const handleSearch = () => {
      pageTable.value.onLoad();
    };
    onMounted(() => {
      handleSearch();
    });
    return {
      async changeStatus(row: any) {
        const openMsg = '开启分销后，请前往分销管理，进行分销设置';
        const closeMsg =
          '关闭后，分销功能将不可使用，不再产生佣金,所有已生成的推广码都将失效';
        try {
          await MessageBox.confirm(
            `${row.distributionStatus ? openMsg : closeMsg}`,
            `是否确认${row.distributionStatus ? '开启' : '关闭'}分销?`,
            {
              center: true,
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'info',
            },
          );
          await MertmentApi.updateDistributionStatus({
            id: row.id,
            distributionStatus: row.distributionStatus ? '1' : '0',
          });
          pageTable.value.onLoad();
        } catch (error) {
          row.distributionStatus = !row.distributionStatus;
        }
      },
      ...toRefs(state),
      dialogVisible,
      pageTable,
      handleSearch,
      findPage: MertmentApi.findPage,
      clear: () => {
        state.SearchParams = { status: '' };
      },
      OpenAccount: () => {
        state.isAdd = true;
        const el: any = dialogVisible.value;
        el.dialoginit();
      },
      CloseDialog: () => {
        handleSearch();
      },
      editData: (row: any) => {
        state.isAdd = false;
        const el: any = dialogVisible.value;
        el.dialoginit(row);
      },
    };
  },
});
