
























































































import { SearchParam, StatusText } from './index';
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import pageSizeChange from '@/mixin/pagesize';
import { AccountApi } from '@/api/systemmanage';
import { Message, MessageBox } from 'element-ui';
import AddAccount from './addaccount.vue';
import ResetPwd from './resetpwd.vue';
export default defineComponent({
  mixins: [pageSizeChange],
  components: {
    AddAccount,
    ResetPwd,
  },
  setup() {
    const pageTable = ref();
    const Adddialog = ref();
    const Resetdialog = ref();
    const SearchParams = reactive({ status: '0' } as SearchParam);
    const state = reactive({
      SearchParams,
      StatusList: Object.keys(StatusText).map(key => ({
        label: (StatusText as any)[key],
        value: key,
      })),
      StatusText,
      isAdd: false,
    });
    const edit = (row: any) => {
      if (row?.id) {
        state.isAdd = false;
      } else {
        state.isAdd = true;
      }
      Adddialog.value.dialoginit(row);
    };
    const handleSearch = () => {
      pageTable.value.onLoad();
    };
    const del = (row: { id: string }) => {
      MessageBox.confirm('是否确认删除该账号？', {
        distinguishCancelAndClose: true,
        showClose: false,
        center: true,
      })
        .then(() => {
          AccountApi.deleteById(row.id).then(() => {
            Message.success('删除成功');
            handleSearch();
          });
        })
        .catch(() => {
          Message.info('取消操作了');
        });
    };
    onMounted(() => {
      handleSearch();
    });
    const clear = () => {
      state.SearchParams = reactive({ status: '0' } as SearchParam);
    };
    return {
      ...toRefs(state),
      pageTable,
      Adddialog,
      Resetdialog,
      findPage: AccountApi.findPage,
      handleSearch,
      clear,
      edit,
      del,
      openStatusChange: async (row: { status: string; id: string }) => {
        let res = {} as any;
        res =
          row?.status === '0'
            ? await AccountApi.enable(row.id)
            : await AccountApi.disable(row.id);
        if (res.success)
          Message.success(`${row?.status === '0' ? '启用' : '停用'}成功`);
        handleSearch();
      },
      resetpwd: (row: { id: any }) => {
        Resetdialog.value.dialoginit(row.id);
      },
    };
  },
});
