














































































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import { ShopmageApi, WriteoffApi } from '@/api/opermanage';
import { SearchParam } from './index';
import request from '@/utils/request';
import Add from './add.vue';
import { Message, MessageBox } from 'element-ui';
import pageSizeChange from '@/mixin/pagesize';
import router from '@/router';
import distribuData from './distribuData.vue';
import MyNextLevel from './myNextLevel.vue';
import { amountToFixedByDecimalDigits } from '@/utils/tools';
export default defineComponent({
  mixins: [pageSizeChange],
  components: {
    Add,
    distribuData,
    MyNextLevel,
  },
  setup() {
    const SearchParams = reactive({
      merchantId: '',
      merchantName: '',
    } as SearchParam);
    const pageTable = ref();
    const Adddialog = ref();
    const dialogVisible = ref();
    const RelationDialog = ref();
    const ResetPassword = ref();
    const MyNextLevel = ref();
    const state = reactive({
      SearchParams,
      DateTime: [],
      ShopList: [],
      isAdd: false,
    });
    const handleSearch = () => {
      pageTable.value.onLoad();
    };
    const Nameisdisd = computed(() => {
      if (state.SearchParams.merchantId) return true;
      else return false;
    });
    const edit = (row: any) => {
      let adddata;
      if (!row) {
        state.isAdd = true;
      } else {
        state.isAdd = false;
        adddata = row;
      }
      Adddialog.value.dialoginit(adddata);
    };
    const del = (row: any) => {
      MessageBox.confirm('是否确认删除核销员？', {
        distinguishCancelAndClose: true,
        showClose: false,
        center: true,
      })
        .then(() => {
          WriteoffApi.delete(row.id).then(() => {
            Message.success('删除成功');
            handleSearch();
          });
        })
        .catch(() => {
          Message.info('取消操作了');
        });
    };
    const getShoeList = () => {
      ShopmageApi.findPage({
        pageSize: 9999,
        currentPage: 1,
        merchantName: state.SearchParams?.merchantName,
      }).then(res => {
        state.ShopList = res.data.records;
      });
    };
    onMounted(() => {
      const routers: any = router;
      state.SearchParams.merchantId = routers.history.current.params.merchantId;
      state.SearchParams.merchantName =
        routers.history.current.params.merchantName;
      handleSearch();
    });
    return {
      addNextLevel(row: any) {
        MyNextLevel.value.dialoginit(row);
      },
      ...toRefs(state),
      handleSearch,
      pageTable,
      Adddialog,
      Nameisdisd,
      amountToFixedByDecimalDigits,
      RelationDialog,
      MyNextLevel,
      ResetPassword,
      dialogVisible,
      resetPass(row: any) {
        ResetPassword.value.dialoginit(row);
      },
      getShoeList,
      relationWeChat: (row: any) => {
        RelationDialog.value.dialoginit(row);
      },
      getDistribuDate: (row: any) => {
        const el: any = dialogVisible.value;
        el.dialoginit(row);
      },
      findPage: (params: any) => {
        return request.post(
          `/scAdmin/admin/distributionManager/findMerchantUserTotalPage`,
          params,
        );
      },
      edit,
      del,
      DateChange: (date: (string | undefined)[]) => {
        state.SearchParams.registrationStartTime = date?.[0];
        state.SearchParams.registrationEndTime = date?.[1];
      },
      clear: () => {
        state.SearchParams = {
          merchantId: state.SearchParams.merchantId,
          // merchantName: state.SearchParams.merchantName,
        };
        state.DateTime = [];
      },
      CloseDialog: () => {
        handleSearch();
      },
    };
  },
});
