/**
 *搜索参数
 */
export interface SearchParam {
  merchantName?: string; //持卡人
  status?: string; // 核销员
}

/**
 *状态
 */
export const StatusText = {
  '': '全部',
  UPPER: '上架',
  LOWER: '下架',
};
/**
 * 按钮操作
 * */
export const advertsActions = {
  /**上架*/
  UP: 'UP',
  /**下架*/
  DOWN: 'DOWN',
  /**编辑*/
  EDIT: 'EDIT',
  /**删除*/
  DELETE: 'DELETE',
};
export const getActiityActions = (item: { status: any }) => {
  const actions = [
    {
      item: item,
      type: advertsActions.UP,
      text: '上架',
      status: [() => (StatusText as any)[item.status] === '下架'],
    },
    {
      item: item,
      type: advertsActions.DOWN,
      text: '下架',
      status: [() => (StatusText as any)[item.status] === '上架'],
    },
    {
      item: item,
      type: advertsActions.EDIT,
      text: '编辑',
      status: [() => StatusText as any], //[item.status] === '下架'
    },

    {
      item: item,
      type: advertsActions.DELETE,
      text: '删除',
      status: [() => StatusText as any],
    },
  ];

  const activeActions = actions.filter(item => item.status.every(c => c()));
  return activeActions;
};

/**
 * 添加广告 参数
 */
export interface AddParam {
  id?: string;
  merchantId?: string;
  merchantName?: string;
  theme?: string;
  uploadType?: string;
  uploadImg?: string;
  cardId?: any;
  pushTime?: string;
  pullTime?: string;
  DateTime?: any;
}
