/**
 *商户列表搜索参数
 */
export interface SearchParam {
  name?: string; // 名称
  mobile?: string; //手机号
  status?: string; // 状态
}

/**
 *核销统计 搜索参数
 */
export interface WritefParam {
  merchantId?: string; //商户id
  storeId?: string; // 门店
  mobile?: string; //持卡人
  verifierId?: string; // 核销员
  startTime?: string;
  endTime?: string;
}

/**
 *核销统计 门店集合
 */
export const ShopText = {
  a: '张三',
  b: '李四',
  c: '王五',
};

/**
 *核销统计 核销人集合
 */
export const WriteoffText = {
  a: '张三',
  b: '李四',
  c: '王五',
};

/**
 *售卡记录 搜索参数
 */
export interface CardSalesParam {
  mobile?: string;
  merchantId?: string;
  cardStatus?: string;
  startTime?: string; //开始日期
  endTime?: string; //结束日期
  storeId?: string; //售卡门店
}

/**
 *售卡记录 卡状态
 */
export const CardStatusText = {
  ALL: '全部',
  '0': '正常',
  '1': '已过期',
  '2': '已退款',
};
