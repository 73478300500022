






























































































































































import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import { SearchParam } from './index';
import { FinalmageApi } from '@/api/finalmanage';
import Popover from '@/components/Popover.vue';
import { amountToFixedByDecimalDigits } from '@/utils/tools';
import Refund from './refund.vue';
export default defineComponent({
  components: {
    Popover,
    Refund,
  },
  setup() {
    const pageTable = ref();
    const refundDialog = ref();
    const SearchParams = reactive({} as SearchParam);
    const state = reactive({
      SearchParams,
      DateTime: [],
      tableData: [],
      refundInfo: {},
      selArr: [],
    });
    const handleSearch = () => {
      pageTable.value.onLoad();
    };
    const clear = () => {
      state.DateTime = [];
      state.SearchParams = {};
    };
    const gorefund = (row: {
      nickName: any;
      shareCardName: any;
      salesCardPrice: any;
      orderNo: any;
    }) => {
      const { nickName, shareCardName, salesCardPrice, orderNo } = row;
      state.refundInfo = {
        nickName,
        shareCardName,
        salesCardPrice,
        orderNo,
      };
      refundDialog.value.dialoginit(state.refundInfo);
    };
    onMounted(() => {
      handleSearch();
    });
    return {
      ...toRefs(state),
      pageTable,
      refundDialog,
      handleSearch,
      clear,
      amountToFixedByDecimalDigits,
      findPage: FinalmageApi.orderfindPage,
      refundRemarkById: FinalmageApi.refundRemarkById,
      gorefund,
      DateChange: (date: string[]) => {
        state.SearchParams.startTime = date?.[0];
        state.SearchParams.endTime = date?.[1];
      },
      handleSelectionChange: (arr: any) => {
        state.selArr = arr.map((item: { orderNo: string }) => {
          return item.orderNo;
        });
      },
      orderExport: () => {
        FinalmageApi.orderInfoExport({
          ...state.SearchParams,
          ids: state.selArr,
        }).then(() => {
          setTimeout(() => {
            pageTable.value.clearSelection();
          }, 200);
        });
      },
    };
  },
});
