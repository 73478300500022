
















































































































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import { AddParam } from './index';
import WangEditor from '@/components/WangEditor.vue';
import router from '@/router';
import { Message } from 'element-ui';
import { copy, scale, Yscale, isNums } from '@/constants/mertmanget';
import { CouponmageApi } from '@/api/opermanage';
export default defineComponent({
  components: {
    WangEditor,
  },
  setup() {
    const Adddata = reactive(({
      couponType: '0',
      timeType: '0',
      validDays: '0',
    } as unknown) as AddParam);
    const CouponForm = ref();
    const validDays = (rule: any, value: any, callback: any) => {
      if (!Adddata.effectDays) {
        callback(new Error(`请输入T天生效`));
      }
      callback();
    };
    const goback = () => {
      router.push('/opermanage/couponlist');
    };

    const state = reactive({
      Adddata,
      DateTime: [],
      Formrule: {
        couponType: [
          { required: true, message: '请选择优惠券类型', trigger: 'blur' },
        ],
        couponName: [
          { required: true, message: '请输入券名称', trigger: 'blur' },
        ],
        timeType: [
          { required: true, message: '请选择可用时间类型', trigger: 'blur' },
        ],
        DateTime: [
          {
            type: 'array',
            required: true,
            message: '请选择固定日期',
            trigger: 'blur',
          },
        ],
        validDays: [
          { required: true, message: '请输入自领取后T天生效', trigger: 'blur' },
          { validator: validDays },
        ],
        faceValue: [
          { required: true, message: '请输入券面值金额', trigger: 'blur' },
        ],
        beginValue: [
          { required: true, message: '请输入起用金额', trigger: 'blur' },
        ],
        couponRemark: [
          { required: true, message: '请添加券规则说明', trigger: 'blur' },
        ],
        remarks: [{ required: true, message: '请输入备注', trigger: 'blur' }],
      },
      type: null,
    });
    const Isdisabled = computed(() => state.type === '1');
    onMounted(() => {
      const routers: any = router;
      if (routers.history.current.params.id) {
        CouponmageApi.findById(routers.history.current.params.id).then(res => {
          const data = res.data;
          state.Adddata = {
            id: data.id,
            couponName: data.couponName, // 优惠券名称
            couponType: data.couponType, //优惠券类型
            timeType: data.timeType,
            startTime: data.timeType === '0' ? data.startTime : '',
            endTime: data.timeType === '0' ? data.endTime : '',
            DateTime:
              data.timeType === '0' ? [data.startTime, data.endTime] : ['', ''],
            validDays: data.validDays,
            effectDays: data.effectDays,
            faceValue: scale(data.faceValue),
            beginValue: scale(data.beginValue),
            status: data.status, // 状态
            couponRemark: data.couponRemark,
            remarks: data.remarks,
          };
          Adddata.effectDays = data.effectDays;
        });
      }
      state.type = routers.history.current.params.type;
    });

    return {
      ...toRefs(state),
      CouponForm,
      goback,
      Isdisabled,
      scale,
      isNums,
      DateChange: (date: (string | undefined)[]) => {
        state.Adddata.startTime = date?.[0];
        state.Adddata.endTime = date?.[1];
      },
      keepdate: () => {
        CouponForm.value.validate((val: boolean) => {
          if (!val) return;
          const params = copy(state.Adddata);
          delete params.DateTime;
          params.faceValue = Yscale(params.faceValue);
          params.beginValue = Yscale(params.beginValue);
          const routers: any = router;
          const Apipost = routers.history.current.params.id
            ? CouponmageApi.edit
            : CouponmageApi.add;
          const msg = routers.history.current.params.id
            ? '修改成功'
            : '新增成功';
          Apipost(params).then(() => {
            Message.success(msg);
            goback();
          });
        });
      },
    };
  },
});
