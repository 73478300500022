import request from '../utils/request';

export const UsermageApi = {
  findPage(params: any) {
    return request.post('/scAdmin/admin/userManager/findPage', params);
  },
  userFreeze(params: any) {
    return request.put('/scAdmin/admin/userManager/userFreeze', params); //冻结 取消冻结
  },
};

//主、副卡用户管理
export const CardManageApi = {
  findMainCard(params: any) {
    return request.post('/scAdmin/admin/userCard/findMainCard', params);
  },
  findViceCard(params: any) {
    return request.post('/scAdmin/admin/userCard/findViceCard', params);
  },
  findVerifierInfo(params: any) {
    return request.post('/scAdmin/admin/userManager/findVerifierInfo', params); //核销详情
  },
  saveVerifierRecord(params: any) {
    return request.post(
      '/scAdmin/admin/userManager/saveVerifierRecord',
      params,
    ); //保存核销
  },
  VerifierCoupon(params: any) {
    return request.post('/scAdmin/admin/userManager/verifierCoupon', params); //仅核销优惠券
  },
  exportMainCard(params: any) {
    return request.post('/scAdmin/admin/userCard/exportMainCard', params, {
      responseType: 'blob',
    }); //主卡导出
  },
  exportViceCard(params: any) {
    return request.post('/scAdmin/admin/userCard/exportViceCard', params, {
      responseType: 'blob',
    }); //副卡导出
  },
};
