<template>
  <div :class="limitNum < 2 && (value || '').length > 0 && 'main'">
    <el-upload
      :limit="limitNum"
      :headers="headers"
      :action="getUploadUrl"
      list-type="picture-card"
      :file-list="fileList"
      :before-upload="handleBeforeUpload"
      :on-success="handleSuccess"
      :on-exceed="handleExceed"
    >
      <i class="el-icon-plus avatar-uploader-icon" />
      <div slot="tip">
        <div>
          <span v-if="Extension"
            >支持<span v-for="(item, index) in Extension" :key="index"
              >{{ item }}{{ index + 1 === Extension.length ? '' : '、' }}</span
            >格式，</span
          >
          <span v-if="psalwidth > 0">{{
            psalwidth > 0 ? `建议尺寸${psalwidth}*${psalheight}，` : ''
          }}</span>
          图片大小不得超过{{ getMaxSize }}，最多上传{{ limitNum }}张
        </div>
      </div>
      <div slot="file" slot-scope="{ file }">
        <img class="el-upload-list__item-thumbnail" :src="file.url" />
        <span class="el-upload-list__item-actions">
          <span
            class="el-upload-list__item-preview"
            @click="handlePictureCardPreview(file)"
          >
            <i class="el-icon-zoom-in" />
          </span>
          <span class="el-upload-list__item-delete" @click="handleRemove(file)">
            <i class="el-icon-delete" />
          </span>
        </span>
      </div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" />
    </el-dialog>
  </div>
</template>

<script>
import { divide } from 'number-precision';
import storage from 'store';
import { ACCESS_TOKEN } from '@/constants/storage';
export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    limit: {
      type: Number,
      default: 15,
    },
    size: {
      type: Number,
      default: 1024 * 1024,
    },
    value: [Array, String],
    // 上传类型
    restformat: {
      type: Array,
      default() {
        return ['image/png', 'image/jpg', 'image/jpeg'];
      },
    },
    width: {
      type: [Number, String],
      default: 0,
    },
    height: {
      type: [Number, String],
      default: 0,
    },
    psalwidth: {
      type: [Number, String],
      default: 0,
    },
    psalheight: {
      type: [Number, String],
      default: 0,
    },
    // 扩展文件类型
    Extension: {
      type: Array,
      default() {
        return ['jpg', 'png'];
      },
    },
  },
  computed: {
    getUploadUrl() {
      return '/api/filecenter/upload';
    },
    getMaxSize() {
      if (this.maxSize < 1024 * 1024) {
        return divide(this.maxSize, 1024) + 'KB';
      }
      return divide(this.maxSize, 1024 * 1024) + 'M';
    },
  },
  created() {
    this.setDefaultValue(this.value);
  },
  data() {
    return {
      maxSize: this.size,
      imgHost: 'http://ka-img-dev.billbear.cn',
      dialogImageUrl: '',
      dialogVisible: false,
      isClickUpload: false,
      limitNum: this.limit,
      fileList: [],
      headers: {
        Authorization: 'Bearer ' + storage.get(ACCESS_TOKEN),
      },
    };
  },
  watch: {
    value(val) {
      //if (!this.isClickUpload) {
      this.setDefaultValue(val);
      //}
    },
  },
  methods: {
    setDefaultValue(val) {
      this.limitNum = this.limit;
      if (typeof val !== 'object') {
        this.limitNum = 1;
        if (val) {
          this.fileList = [
            {
              response: { fileKey: val },
              url: this.imgHost + '/' + val,
            },
          ];
        } else {
          this.fileList = [];
        }
        return;
      }

      if (val.length === 0) {
        this.fileList = [];
        return;
      }

      this.fileList = val.map(key => ({
        response: { fileKey: key },
        url: this.imgHost + '/' + key,
      }));
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove(file) {
      if (typeof this.value === 'string') {
        this.fileList = [];
        this.dispatch();
        return;
      }
      this.isClickUpload = true;
      const index = this.fileList.findIndex(item => item.uid === file.uid);
      if (index >= 0) {
        this.fileList.splice(index, 1);
        this.dispatch();
      }
    },
    handleBeforeUpload(file) {
      if (file.size > this.size) {
        this.$message.error('图片大小不能超过 ' + this.getMaxSize);
        return false;
      }
      if (this.restformat.length > 0 && !this.restformat.includes(file.type)) {
        this.$message.error('请选择对应类型上传');
        return false;
      }
      const that = this;
      const isSize = new Promise(function(resolve, reject) {
        const width = that.width;
        const height = that.height;
        const _URL = window.URL || window.webkitURL;
        const img = new Image();
        img.onload = function() {
          if (width === 0 || height === 0) {
            resolve();
            return;
          }
          const valid = img.width == width && img.height == height;
          valid ? resolve() : reject();
        };
        img.src = _URL.createObjectURL(file);
      }).then(
        () => {
          return file;
        },
        () => {
          this.$message.error(
            `图片大小必须是等于${this.width}*${this.height}!`,
          );
          return Promise.reject();
          // return false;//必须加上return false; 才能阻止
        },
      );
      return isSize;
    },
    handleExceed() {
      this.$message.error('图片上传数量不能超过 ' + this.limitNum + '张');
    },
    handleSuccess(response, file, fileList) {
      if (response.fileKey) {
        this.isClickUpload = true;
        this.fileList = fileList;
        this.dispatch();
      } else {
        this.$message.error('上传失败');
        fileList.pop();
        this.fileList = fileList;
      }
    },
    dispatch() {
      const filelist = this.fileList.map(item => item.response.fileKey);
      const result = this.limitNum === 1 ? filelist[0] : filelist;
      this.$emit('change', result);
      this.$parent.$emit('el.form.change', result);
    },
  },
};
</script>
<style lang="less">
.main {
  .el-upload--picture-card {
    display: none;
  }
}
</style>
