










































































import {
  defineComponent,
  reactive,
  ref,
  toRefs,
  nextTick,
} from '@vue/composition-api';
import request from '@/utils/request';
import Upload from '@/components/Upload.vue';
import pageSizeChange from '@/mixin/pagesize';
export default defineComponent({
  components: {
    Upload,
  },
  mixins: [pageSizeChange],
  props: {
    isAdd: {
      type: Boolean,
    },
  },
  setup() {
    const pageTable = ref();
    const AddDialog = ref();
    const state = reactive({
      headInfo: {},
      SearchParams: {
        startTime: '',
        endTime: '',
        distributionId: '',
      },
      programTime: ['', ''],
      filetype: ['image/png', 'image/jpg'],
      Extension: ['jpg', 'png'],
    });
    return {
      getSummaries(param: any) {
        const { columns, data } = param;
        const sums: any = [];
        columns.forEach((column: any, index: any) => {
          if (index === 0) {
            sums[index] = '合计';
            return;
          }
          if (index === 1) {
            sums[index] = '';
            return;
          }
          const values = data.map((item: any) => Number(item[column.property]));
          if (!values.every((value: any) => isNaN(value))) {
            sums[index] =
              values.reduce((prev: any, curr: any) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return prev + curr;
                } else {
                  return prev;
                }
              }, 0) / 100;
            sums[index] += '';
          } else {
            sums[index] = '';
          }
        });
        return sums;
      },
      ...toRefs(state),
      clear: () => {
        state.programTime = [];
        state.SearchParams.startTime = state.SearchParams.startTime = '';
      },
      AddDialog,
      pageTable,
      handleSearch() {
        pageTable.value.onLoad();
      },
      DateChange: (date: string[]) => {
        state.SearchParams.startTime = date?.[0];
        state.SearchParams.endTime = date?.[1];
      },
      findPage: (params: any) => {
        return request.post(
          '/scAdmin/admin/distributionManager/findMerchantUserDetailPage',
          params,
        );
      },
      dialoginit: async (row: any) => {
        state.headInfo = {
          username: row.username,
          distributionOrderTotalNum: row.distributionOrderTotalNum,
        };
        const date = new Date();
        const startTimeStamp = `${date.getTime() - 3600 * 1000 * 24 * 30}`;
        const startDate = new Date(+startTimeStamp);
        const startMonth =
          startDate.getMonth() + 1 < 10
            ? `0${startDate.getMonth() + 1}`
            : startDate.getMonth() + 1;
        const endMonth =
          date.getMonth() + 1 < 10
            ? `0${date.getMonth() + 1}`
            : date.getMonth() + 1;
        const startTime = `${startDate.getFullYear()}-${startMonth}-${
          startDate.getDate() < 10
            ? '0' + startDate.getDate()
            : startDate.getDate()
        }`;
        const endTime = `${date.getFullYear()}-${endMonth}-${
          date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
        }`;
        state.programTime = [startTime, endTime];
        state.SearchParams = {
          distributionId: row.id,
          startTime,
          endTime,
        };
        AddDialog.value.open();
        nextTick(() => {
          pageTable.value.onLoad();
        });
      },
    };
  },
});
