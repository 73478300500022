






































































































































































import router from '@/router';
import { divide } from 'number-precision';
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import { WritefParam } from './index';
import { BusinessApi, ShopmageApi, WriteoffApi } from '@/api/opermanage';
export default defineComponent({
  setup() {
    const SearchParams = reactive({
      verifierId: '',
      storeId: '',
    } as WritefParam);
    const WriteoffDialog = ref();
    const pageTable = ref();
    const state = reactive({
      WriteoffList: [],
      SearchParams,
      ShopList: [],
      DateTime: [],
      selectArr: [],
      merchantId: '',
      verifTotal: {
        totalVerifierNum: 0,
        totalVerifierAmount: 0,
        totalConsumeAmount: 0,
      } as any,
      verifInfo: {},
      params: {
        pageSize: 9999,
        currentPage: 1,
        merchantId: '',
      },
      divide,
    });
    const divideVal = (val: any) => {
      return divide(val);
    };
    const handleSearch = () => {
      pageTable.value.onLoad();
    };
    const getShopList = () => {
      ShopmageApi.findPage(state.params).then(res => {
        state.ShopList = res.data.records; //门店列表
      });
    };
    const getverTotal = () => {
      BusinessApi.totalVerifierBoard(state.params).then(res => {
        state.verifTotal = res.data; //核销统计
      });
    };
    const getWriteoffList = () => {
      WriteoffApi.findPage(state.params).then(res => {
        state.WriteoffList = res.data.records; //核销员列表
      });
    };
    const exports = () => {
      BusinessApi.vferexport({ ...state.params, ids: state.selectArr });
    };
    onMounted(() => {
      const routers: any = router;
      state.SearchParams.merchantId = state.params.merchantId =
        routers.history.current.params.id;
      getverTotal();
      handleSearch();
    });
    return {
      ...toRefs(state),
      handleSearch,
      WriteoffDialog,
      pageTable,
      getverTotal,
      getWriteoffList,
      findPage: BusinessApi.vferfindPage,
      getShopList,
      exports,
      divideVal,
      clear: () => {
        state.SearchParams = {
          merchantId: state.SearchParams.merchantId,
          verifierId: '',
          storeId: '',
        };
        state.DateTime = [];
      },
      DateChange: (date: (string | undefined)[]) => {
        state.SearchParams.startTime = date?.[0];
        state.SearchParams.endTime = date?.[1];
      },
      handleSelectChange: (checkedRow: {
        map: (arg0: (item: { id: any }) => any) => never[];
      }) => {
        state.selectArr = checkedRow.map((item: { id: any }) => {
          return item.id;
        });
      },
      CloseDialog: () => {
        // console.log();
        WriteoffDialog.value.dialogFormVisibles = false;
      },
      ShowDel: (row: { id: string }) => {
        BusinessApi.showvferDel(row.id).then(res => {
          state.verifInfo = res.data;
          WriteoffDialog.value.open();
        });
      },
    };
  },
});
