





































































































































































import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import { CouponText } from '../Coupon/index';
import { IsCouponsApi } from '@/api/opermanage';
import { Message } from 'element-ui';
import Dialog from '@/components/modules/Dialog.vue';
export default defineComponent({
  components: { Dialog },
  setup(props, { emit }) {
    const AddDialog = ref();
    const table = ref();
    const state = reactive({
      tableData: [] as any,
      search: '', //搜索条件 手机号
      AddCoupon: {
        userId: '',
        mobile: '',
        sharecardId: '',
        cardNo: '',
        couponId: '',
        couponType: '',
        reissueNumber: '1',
      },
      ShareCardList: [],
      couponName: '',
      selArr: {} as any,
      CouponList: [],
      keepDatas: {} as any,
      dialogVisible: false,
      tableDatas: [], //共享卡数据
    });
    const couponIdChange = () => {
      const parent = state.CouponList.find(
        i => (i as any).value === state.AddCoupon.couponId,
      ) as any;
      state.AddCoupon.couponType = parent.couponType;
      state.keepDatas.couponNowName = parent.label;
      state.couponName = (CouponText as any)[parent.couponType];
      return state.AddCoupon;
    };
    // 获取共享卡下拉
    const getShareCardList = () => {
      IsCouponsApi.selectUserShareCard(state.tableData?.[0]?.userId).then(
        res => {
          state.ShareCardList = res.data;
        },
      );
    };
    // 获取共享卡主、副卡数据
    const getTableDatas = () => {
      IsCouponsApi.selectUserCardNo({
        userId: state.tableData?.[0].userId,
        shareCardId: state.AddCoupon.sharecardId,
      }).then(res => {
        state.tableDatas = res.data.selectShareCardInfoList || [];
        state.keepDatas.merChartName = res.data.merchantName;
      });
    };
    onMounted(() => {
      console.log(state.tableData);

      AddDialog.value.open();
    });
    return {
      ...toRefs(state),
      AddDialog,
      getShareCardList,
      couponIdChange,
      getTableDatas,
      table,
      shardCardCheck: (selection: string | any[], row: any) => {
        table.value.clearSelection();
        state.selArr = {};
        if (selection.length === 0) {
          state.AddCoupon.cardNo = '';
          return;
        }
        if (row) {
          state.selArr = row;
          state.AddCoupon.cardNo = state.selArr.cardNo;
          table.value.toggleRowSelection(row, true);
        }
        console.log(state.selArr);
      },
      finishkeep: () => {
        console.log('保存');
        const ObjectMsg = [
          { key: 'userId', msg: '请输入有用手机号' },
          { key: 'mobile', msg: '请输入有用手机号' },
          { key: 'sharecardId', msg: '请选择共享卡' },
          { key: 'cardNo', msg: '请选择共享卡号' },
          { key: 'couponId', msg: '请选择优惠券' },
        ];
        let i;
        for (i in ObjectMsg) {
          if (!(state.AddCoupon as any)[ObjectMsg[i].key]) {
            Message.warning(`${ObjectMsg[i].msg}`);
            return;
          }
        }
        const { couponType, reissueNumber } = state.AddCoupon;
        state.keepDatas = {
          merChartName: state.keepDatas.merChartName,
          shareCardName: (state.ShareCardList.find(
            i => (i as any).value === state.AddCoupon.sharecardId,
          ) as any)?.label,
          couponNowName: state.keepDatas.couponNowName,
          phone: state.tableData?.[0]?.mobile,
          couponName: state.couponName,
          couponType,
          reissueNumber,
        };
        console.log(state.keepDatas);

        state.dialogVisible = true;
      },
      // 二次确认提交
      keepData: () => {
        IsCouponsApi.reissCoupon(state.AddCoupon).then(() => {
          Message.success('补发优惠券成功');
          AddDialog.value.dialogFormVisibles = false;
          emit('confirm');
        });
      },
      cancel: (key: string) => {
        if (key === '1') {
          AddDialog.value.dialogFormVisibles = false;
          emit('confirm');
        } else if (key === '2') {
          state.dialogVisible = false;
        } else {
          state.dialogVisible = false;
          AddDialog.value.dialogFormVisibles = false;
          emit('confirm');
        }
      },
      selByMobile: () => {
        if (!state.search) {
          Message.warning('请输入手机号');
          return;
        }
        if (!/^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(state.search)) {
          Message.warning('手机号格式不正确');
          return;
        }
        IsCouponsApi.selectUserInfoByMobile(state.search).then(res => {
          state.tableData = [];
          state.tableDatas = [];
          state.CouponList = [];
          state.AddCoupon = {
            userId: '',
            mobile: '',
            sharecardId: '',
            cardNo: '',
            couponId: '',
            couponType: '',
            reissueNumber: '1',
          };
          state.tableData.push({
            ...res.data,
          });
          if (res.data.userId) {
            state.AddCoupon.userId = res.data.userId;
            state.AddCoupon.mobile = res.data.mobile;
            getShareCardList();
          }
        });
      },
      // 获取优惠券下拉
      sharecardIdChange: () => {
        state.AddCoupon.couponId = '';
        state.couponName = '';
        state.tableDatas = [];
        state.keepDatas.merChartName = '';
        getTableDatas();
        IsCouponsApi.selectCouponInfo({
          userId: state.tableData?.[0].userId,
          shareCardId: state.AddCoupon.sharecardId,
        }).then(res => {
          state.CouponList = res.data;
        });
      },
    };
  },
});
