<template>
  <div class="datepicker">
    <el-date-picker
      v-model="valueDate"
      :type="datetype"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      @change="DateChang"
      :value-format="format"
      :picker-options="endDateOpt"
      size="small"
    >
    </el-date-picker>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
    startname: {
      type: String,
      default: '',
    },
    endname: {
      type: String,
      default: '',
    },
    // 是否禁用最早日期
    options: {
      type: Boolean,
      default: false,
    },
    // 时间显示类型
    datetype: {
      type: String,
      default: 'daterange', //datetimerange
    },
  },
  data() {
    return {
      endDateOpt: {
        // 时间选择器
        disabledDate: time => {
          if (this.options == true) {
            return time.getTime() < Date.now(this.valueDate?.[0]) - 8.64e7;
          }
        },
      },
      valueDate: [],
      createTimeEnd: '',
      createTimeStart: '',
    };
  },
  computed: {
    format() {
      if (this.datetype == 'datetimerange') {
        return 'yyyy-MM-dd HH:mm:ss';
      } else {
        return 'yyyy-MM-dd';
      }
    },
  },
  created() {
    this.initDate();
  },
  watch: {
    value() {
      this.initDate();
    },
  },
  methods: {
    DateChang() {
      const data = {
        ...this.value,
        [this.startname]: this.valueDate?.[0],
        [this.endname]: this.valueDate?.[1],
      };
      // console.log(this.valueDate,data)
      this.$emit('input', data);
      this.$emit('change', data);
      // console.log(data);
    },
    initDate() {
      if (this.value?.[this.startname]) {
        this.valueDate = [
          this.value?.[this.startname],
          this.value?.[this.endname],
        ];
      } else {
        this.valueDate = [];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
// .el-range-editor.el-input__inner {
//   display: inline-flex;
//   align-items: center;
//   padding: 3px 10px;
//   width: 327px!important;
// }
</style>
