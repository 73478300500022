



















































































import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import router from '@/router';
import request from '@/utils/request';
import { Message } from 'element-ui';
function getCards() {
  const state = reactive({
    cards: [],
  });
  async function findKeyValueByMerchantId(merchantId: any) {
    const res = await request.get(
      `scAdmin/admin/shareCard/findKeyValueByMerchantId/${merchantId}`,
    );
    state.cards = res.data;
  }
  return {
    ...toRefs(state),
    findKeyValueByMerchantId,
  };
}
function getDetail() {
  const state = reactive({
    detailEntity: {
      cardIds: [],
    },
  });
  async function detailFindById(merchantId: any) {
    const res = await request.get(
      `scAdmin/admin/merchantDistribution/findById/${merchantId}`,
    );
    if (res.data) {
      res.data.oneDistributionPrice = res.data.oneDistributionPrice / 100;
      res.data.platformDistributionPrice =
        res.data.platformDistributionPrice / 100;
      res.data.twoDistributionPrice = res.data.twoDistributionPrice / 100;
    }
    state.detailEntity = res.data || {
      cardIds: [],
    };
  }
  return {
    ...toRefs(state),
    detailFindById,
  };
}
function validateRule() {
  const validatePrice = (rule: any, value: any, callback: any) => {
    const reg = /^\d+(\.\d{0,2})?$/;
    if (!value && value != 0) {
      callback(new Error('金额需大于等于0'));
    } else if (!reg.test(value)) {
      callback(new Error('金额最多输入两位小数'));
    } else {
      callback();
    }
  };
  const state = reactive({
    Formrule: {
      platformDistributionPrice: [
        {
          validator: validatePrice,
          trigger: 'blur',
        },
      ],
      oneDistributionPrice: [{ validator: validatePrice, trigger: 'blur' }],
      twoDistributionPrice: [{ validator: validatePrice, trigger: 'blur' }],
      cardIds: [
        {
          type: 'array',
          required: true,
          message: '请至少选择一张共享卡',
          trigger: 'change',
        },
      ],
    },
  });
  return {
    ...toRefs(state),
  };
}
function submit(isChange: any) {
  const routers: any = router;
  const distributionForm = ref();
  function addOrUpdate(params: any) {
    distributionForm.value.validate(async (val: boolean) => {
      if (!val) return;
      await request.post('scAdmin/admin/merchantDistribution/addOrUpdate', {
        ...params,
        platformDistributionPrice: params.platformDistributionPrice * 100,
        oneDistributionPrice: params.oneDistributionPrice * 100,
        twoDistributionPrice: params.twoDistributionPrice * 100,
        merchantId: routers.history.current.params.merchantId,
      });
      isChange.value = false;
      Message.success('保存成功');
      routers.push('/distribution/merchants');
    });
  }
  return {
    distributionForm,
    addOrUpdate,
  };
}
export default defineComponent({
  beforeRouteLeave(to, from, next) {
    if (this.isChange) {
      this.$confirm(
        '是否确定离开当前页面？离开后当前页面正在编辑的信息将不会保存!',
        '提示',
        {
          center: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        },
      )
        .then(() => {
          next();
        })
        .catch(() => {
          Message.info('取消了');
        });
    } else {
      next();
    }
  },
  watch: {
    detailEntity: {
      deep: true,
      handler() {
        this.isChange = true;
      },
    },
  },
  setup() {
    const isChange = ref(false);
    const { cards, findKeyValueByMerchantId } = getCards();
    const { detailEntity, detailFindById } = getDetail();
    const { Formrule } = validateRule();
    const { distributionForm, addOrUpdate } = submit(isChange);
    onMounted(async () => {
      const routers: any = router;
      await detailFindById(routers.history.current.params.merchantId);
      await findKeyValueByMerchantId(routers.history.current.params.merchantId);
      isChange.value = false;
    });
    return {
      isChange,
      cards,
      detailEntity,
      Formrule,
      distributionForm,
      addOrUpdate,
    };
  },
});
