






























































































import {
  defineComponent,
  nextTick,
  onMounted,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import Upload from '@/components/Upload.vue';
import { copy } from '@/constants/mertmanget';
import { AddParam } from './index';
import { MertmentApi } from '@/api/mertmanget';
import { AdvermageApi } from '@/api/advermanage';
import { Message } from 'element-ui';
export default defineComponent({
  components: {
    Upload,
  },
  props: {
    isAdd: {
      type: Boolean,
    },
  },
  setup(props: any, { emit }) {
    const AddData = reactive(({
      cardId: '',
    } as unknown) as AddParam);
    const AddDialog = ref();
    const AddForm = ref();
    const state = reactive({
      AddData,
      Formrule: {
        merchantId: [
          { required: true, message: '请选择商户名称', trigger: 'blur' },
        ],
        theme: [
          { required: true, message: '请输入主题', trigger: 'blur' },
          {
            min: 3,
            max: 12,
            message: '不能包含空格，限制3-12个字内',
            trigger: 'blur',
          },
        ],
        uploadImg: [
          { required: true, message: '请添加商户logo', trigger: 'blur' },
        ],
        uploadType: [
          { required: true, message: '请选择上传位置', trigger: 'blur' },
        ],
        cardId: [{ required: true, message: '请选择路径', trigger: 'blur' }],
        DateTime: [
          { required: true, message: '请选择有效期', trigger: 'blur' },
        ],
      },
      MerchantList: [],
      PositionList: [
        {
          value: '0',
          label: '轮播图',
        },
      ],
      PathList: [],
    });
    const getCardList = () => {
      if (!state.AddData.merchantId) {
        return;
      }
      AdvermageApi.findShareCardByMerchantId(state.AddData.merchantId).then(
        res => {
          state.PathList = res.data;
        },
      );
    };
    onMounted(() => {
      // 商户下拉列表
      MertmentApi.findPage({ pageSize: 9999, currentPage: 1, status: 0 }).then(
        res => {
          state.MerchantList = res.data.records;
        },
      );
    });
    return {
      ...toRefs(state),
      AddForm,
      AddDialog,
      getCardList,
      DateChange: (date: (string | undefined)[]) => {
        state.AddData.pushTime = date?.[0];
        state.AddData.pullTime = date?.[1];
      },
      finishkeep: (isAdd: any) => {
        AddForm.value.validate((val: boolean) => {
          if (!val) return;
          const params: any = copy(state.AddData);
          delete params.DateTime;
          params.merchantName = (state.MerchantList.find(
            i => (i as any).id === params.merchantId,
          ) as any).name;
          const ApiPost = isAdd ? AdvermageApi.add : AdvermageApi.edit;
          const msg = isAdd ? '添加成功' : '修改成功';
          ApiPost(params).then(() => {
            Message.success(msg);
            AddDialog.value.dialogFormVisibles = false;
            emit('confirm');
          });
        });
      },
      dialoginit: (row: any) => {
        if (row) {
          state.AddData = {
            id: row.id,
            merchantId: row.merchantId,
            merchantName: row.merchantName,
            theme: row.theme,
            uploadType: row.uploadType,
            uploadImg: row.uploadImg,
            cardId: row.cardId,
            pullTime: row.pullTime,
            pushTime: row.pushTime,
            DateTime: [row.pushTime, row.pullTime],
          };
          getCardList();
        } else {
          state.AddData = reactive({ cardId: '' } as AddParam);
          state.PathList = [];
        }
        AddDialog.value.open();
        nextTick(() => {
          AddForm.value.clearValidate();
        });
      },
      mernameChange: () => {
        state.AddData.cardId = '';
        state.PathList = [];
        getCardList();
      },
    };
  },
});
