/**
 * 列表搜索参数
 */
export interface SearchParam {
  username?: string;
  realName?: string; //账号
  registrationStartTime?: string; // 开始时间
  registrationEndTime?: string; // 结束时间
}

/**
 *状态
 */
export const StatusText = {
  0: '正常',
  2: '禁用',
};

/**
 * 新增账号参数
 */
export interface AddParam {
  id?: string;
  realName?: string; //姓名
  username?: string; // 账号
  mobile?: string; //手机号
  roleIds?: any; // 角色 支付多选
  password?: string; //密码
  confimpsd?: string; // 重置密码
}
