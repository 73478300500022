











































































import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import { FinalmageApi } from '@/api/finalmanage';
import { Message } from 'element-ui';
import { NoNum } from '@/constants/mertmanget';
import WangEditor from '@/components/WangEditor.vue';
export default defineComponent({
  components: {
    WangEditor,
  },
  setup() {
    const setForm = ref();
    const settingInfo = reactive({
      id: '',
      extractType: '0',
      maxExtractPrice: '',
      extractInterval: '',
      startExtractPrice: '',
      extractRemark: '',
    });
    const PriceValid = (rule: any, value: any, callback: any) => {
      if (!value) {
        callback(new Error(`请输入单次提现最大金额`));
      } else if (setForm.value.model.maxExtractPrice > 200) {
        callback(new Error(`单次提现最大金额不超过200`));
      }
      callback();
    };
    const IntervalValid = (rule: any, value: any, callback: any) => {
      if (!value && value != 0) {
        callback(new Error(`请输入提现频次`));
      } else if (setForm.value.model.extractInterval > 30) {
        callback(new Error(`提现频次最多不超过30`));
      }
      callback();
    };
    const satpriceValid = (rule: any, value: any, callback: any) => {
      if (!value) {
        callback(new Error(`请输入单次起提金额`));
      } else if (setForm.value.model.startExtractPrice > 200) {
        callback(new Error(`单次起提金额最多不超过200`));
      }
      callback();
    };
    const state = reactive({
      settingInfo,
      Formrule: {
        extractType: [
          { required: true, message: '请选择提现方式', trigger: 'change' },
        ],
        maxExtractPrice: [
          { required: true, validator: PriceValid, trigger: 'blur' },
        ],
        extractInterval: [
          { required: true, validator: IntervalValid, trigger: 'blur' },
        ],
        startExtractPrice: [
          { required: true, validator: satpriceValid, trigger: 'blur' },
        ],
        extractRemark: [
          { required: true, message: '请设置提现说明', trigger: 'blur' },
        ],
      },
    });
    const handleSearch = () => {
      FinalmageApi.withSetSearch({}).then(res => {
        state.settingInfo = {
          ...res.data,
          maxExtractPrice: Number(res.data.maxExtractPrice) / 100,
          startExtractPrice: Number(res.data.startExtractPrice) / 100,
        };
      });
    };
    onMounted(() => {
      handleSearch();
    });
    return {
      ...toRefs(state),
      setForm,
      NoNum,
      keepdata: () => {
        setForm.value.validate((val: boolean) => {
          if (!val) return;
          const params = {
            ...state.settingInfo,
            maxExtractPrice: Number(state.settingInfo.maxExtractPrice) * 100,
            startExtractPrice:
              Number(state.settingInfo.startExtractPrice) * 100,
          };
          FinalmageApi.withSetSave(params).then(() => {
            Message.success('保存成功');
            handleSearch();
          });
        });
      },
    };
  },
});
