


















































































import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import { MENU_STATUS, MenuTypeText, filterTree } from './index';
import { MenuApi } from '@/api/systemmanage';
import Add from './add.vue';
import { Message } from 'element-ui';
// import { copy } from '@/constants/mertmanget';
export default defineComponent({
  components: {
    Add,
  },
  setup() {
    const Adddialog = ref();
    const state = reactive({
      SearchParams: {
        name: '',
        status: '',
      },
      MENU_STATUS,
      menuData: ['1'],
      MenuTypeText,
      menuRaw: [],
      isAdd: false,
    });
    const search = () => {
      const menuData = filterTree(state.menuRaw, item => {
        if (state.SearchParams.name && state.SearchParams.status) {
          return (
            item.name.includes(state.SearchParams.name) &&
            item.visible === state.SearchParams.status
          );
        }
        if (state.SearchParams.name === state.SearchParams.status) return true;
        if (state.SearchParams.name) {
          return item.name.includes(state.SearchParams.name);
        }
        return item.visible === state.SearchParams.status;
      });
      state.menuData = menuData;
    };
    const handleSearch = () => {
      MenuApi.findTree({}).then(res => {
        state.menuData = res.data;
        state.menuRaw = res.data;
      });
    };
    const edit = (row: any) => {
      state.isAdd = row?.id ? false : true;
      Adddialog.value.dialoginit(row);
    };
    const del = (row: { id: string }) => {
      MenuApi.deleteById(row.id).then(() => {
        Message.success('删除成功');
        handleSearch();
      });
    };
    const clear = () => {
      state.SearchParams = {
        name: '',
        status: '',
      };
    };
    onMounted(() => {
      handleSearch();
    });
    return {
      ...toRefs(state),
      edit,
      clear,
      del,
      search,
      handleSearch,
      Adddialog,
    };
  },
});
