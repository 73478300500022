import request from '../utils/request';

export const MertmentApi = {
  findPage(params: any) {
    return request.post('/scAdmin/admin/merchantManager/findPage', params);
  },
  openAccount(params: any) {
    return request.post('/scAdmin/admin/merchantManager/openAccount', params); //开户
  },
  editChant(params: any) {
    return request.put('/scAdmin/admin/merchantManager/edit', params); //编辑
  },
  updateDistributionStatus(params: any) {
    return request.post(
      '/scAdmin/admin/merchantManager/updateDistributionStatus',
      params,
    ); //状态修改
  },
  selectTitleOption() {
    return request.get('/scAdmin/admin/dataView/selectTitleOption'); //商户、门店下拉
  },
  selectDataOverview() {
    return request.get('/scAdmin/admin/dataView/selectDataOverview'); //查询 数据面板总数据
  },
  selectCoupon() {
    return request.get('/scAdmin/admin/dataView/selectCoupon'); //数据面板  优惠券数据
  },
  selectChartData(params: any) {
    return request.post(`/scAdmin/admin/dataView/selectChartData`, params); //数据面板 柱状图数据
  },
  // 查询副卡信息
  selectViceCard() {
    return request.get(`/scAdmin/admin/dataView/selectViceCard`); //数据面板 柱状图数据
  },
};
