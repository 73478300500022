









































































import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import { IsCouponsApi } from '@/api/opermanage';
import { CouponStatusText } from './index';
export default defineComponent({
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    console.log(props);

    const ResCouDialog = ref();
    const state = reactive({
      tableData: [],
      couponInfo: {},
      mobile: '',
      CouponStatusText,
    });
    const gettableData = () => {
      IsCouponsApi.searchReissueCouponDetail({
        id: props.id,
        mobile: state.mobile,
      }).then(res => {
        state.tableData = res.data.reissueCouponDataList;
        console.log(state.tableData);
        state.couponInfo = res.data;
      });
    };
    const cancel = () => {
      ResCouDialog.value.dialogFormVisibles = false;
      emit('confirm');
    };
    onMounted(() => {
      ResCouDialog.value.open();
      gettableData();
    });
    return {
      ...toRefs(state),
      ResCouDialog,
      gettableData,
      cancel,
    };
  },
});
