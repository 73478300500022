/**
 *优惠券 列表搜索参数
 */
export interface SearchParam {
  couponName?: string; // 优惠券名称
  couponType?: string; //优惠券类型
  status?: string; // 状态
  merchantId?: string;
}

/**
 *优惠券类型
 */
export const CouponText = {
  ALL: '全部',
  0: '自用券',
  1: '分享券',
};

/**
 *优惠券状态
 */
export const StatusText = {
  ALL: '全部',
  0: '启用',
  2: '禁用',
};

/**
 *  添加优惠券参数
 */
export interface AddParam {
  id?: string;
  couponName?: string; // 优惠券名称
  couponType?: string; //优惠券类型
  timeType?: string;
  startTime?: string;
  endTime?: string;
  DateTime?: ['', ''];
  validDays?: string;
  effectDays?: string;
  faceValue?: number;
  beginValue?: number;
  status?: string; // 状态
  couponRemark?: string;
  remarks?: string | undefined | null; // 备注
}
